const Y = {
  productId: "Prompto",
  productId424: "PromptoV2",
  maxExporterProductId: "PromptoExporter3dsMax",
  sketchupExporterProductId: "PromptoExporterSketchup",
  revitExporterProductId: "PromptoExporterRevit",
  env: "development",
  baseUrl: "https://devapiv2.prompto.com/v2",
  apiToken: "eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI2ZTM0ZjliNC03NmUwLTRkYmMtYTBiYy1jODQ0YjBlMzY2NzUiLCJpYXQiOjE0ODczMTgyODEsImV4cCI6MjExODAzODI4MSwiYXVkIjoiYXBpIiwidmVyIjoiMS4wLjAiLCJzY29wZSI6IltcInB1YmxpY2FwaVwiXSJ9.uwAmQwF2XUa4VFnFwnpB2XRi1AWC0pGuy7uoFmGjXHXA5OFgG7ucMj_k9JV1KemK2Z8jFqRLz3yDcsY8ZTWI5w",
  baseImageUrl: "https://devimagecdn.prompto.com",
  cdnUrl: "",
  entityObjectId: "aroundmedia",
  braintreeTokenizationKey: "sandbox_9dmbb642_g2wsxwd4pxj8mwnb",
  applicationDomain: ".prompto.com",
  applicationUrl: "devapp.prompto.com",
  sessionTokenRenewTimeout: 72e5,
  googleStorageBucketId: "devassetsvxgi.vr-tual.media",
  googleMapsAPIKey: "AIzaSyC__OLtuhUjX4al5F6humPLtvZxlnYiyoQ",
  trialSubscriptionPlanId: "around-trial-dev-3",
  defaultAppExpiration: "20s",
  stripe: "pk_test_IVEsmgKankfY2VwIkuLZLol1",
  zippopotamusAPI: "https://api.zippopotam.us/",
  typeFormOrderProjectUrl: "https://prompto.typeform.com/to/Y3aUSL",
  orderSceneDismissCookiePopupTimeout: 6048e5,
  //1 week
  orderSceneDismissCookieName: "orderSceneMaybeLater",
  loggedOutCookieName: "loggedOut",
  aroundMediaPortalUrl: "https://360app-devv2.prompto.com",
  aroundMediaViewerlUrl: "https://360-dev.prompto.com",
  hubspotPortalId: "6072613",
  upsell360FormId: "c9f8688b-31d6-4597-916c-f92c09ebd60f",
  upsellSessionTokenRenewThreshold: 4,
  essentialPackageInfo: "https://prompto.com/subscriptions-essential/",
  defaultMaxProjectCount: "1",
  defaultMaxUserCount: "2",
  showcaseUrl: "https://devshowcase.prompto.com",
  supportBaseLink: "https://support.prompto.com",
  supportBillingPerUnitLink: "https://support.prompto.com/billing-per-unit",
  supportPromptoPortalLink: "https://support.prompto.com/prompto-portal",
  base360ImageUrl: "https://devimagecdn.around.media",
  base360Url: "https://devapi.around.media/v1",
  termsOfService: "https://prompto.com/terms-of-service/",
  privacyPolicy: "https://prompto.com/privacy-policy-2/",
  apiToken360: "eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiIxNmMwNGU4MS1jYTdhLTQxNmItYmMyMC0yNWM2ZWI3MDUxODEiLCJpYXQiOjE0Njc3MjE0MzQsImV4cCI6MjA5ODQ0MTQzNCwiYXVkIjoidmlld2VyIiwidmVyIjoiMS4wLjAiLCJzY29wZSI6IltcInB1YmxpY3ZpZXdlclwiXSJ9.ZjB3XiiQEjcguILhBUB8LzQD2Dcd4ssakiEsHZ_ISByn-McCdVM8EFTluP4RUxLSgytgq3GrNC93PYX3q68oWQ",
  cloudinaryVideoBaseUrl: "https://res.cloudinary.com/prompto-dev/video/upload/",
  cloudinaryImageBaseUrl: "https://res.cloudinary.com/prompto-dev/image/upload/",
  cloudinaryCloudName: "prompto-dev",
  contractTestSigningCookieName: "contractTestSigningCookie",
  appcueFlowWelcomeToPrompto: "-M5gKZ74LwcJvNfA-RUV",
  appcueFlowFirstProjectCreation: "47f26f52-5f82-4d0c-bf77-39a53486eb8e",
  appcueFlowUnitTypes: "36874fad-1be5-4548-ac8c-c914b359b5bc",
  appcueFlowFieldBuilder: "784b606c-629a-49d5-b6d0-f6768963ee2c",
  productLedRegistrationHubspotPortalId: "4943441",
  productLedRegistrationHubspotFormId: "723ec46d-e074-41f1-9935-a9d4630540d0",
  howToSetupUnitTypes: "https://support.prompto.com/billing-per-unit#per-unit-billing-model",
  howToImportUnitsFromCSV: "https://support.prompto.com/how-to-import-units-to-the-showcase-from-csv-file"
}, on = {
  productId: "Prompto",
  productId424: "PromptoV2",
  maxExporterProductId: "PromptoExporter3dsMax",
  sketchupExporterProductId: "PromptoExporterSketchup",
  revitExporterProductId: "PromptoExporterRevit",
  env: "production",
  baseUrl: "https://apiv2.prompto.com/v2",
  apiToken: "eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiIyODE3OTE2Zi05YzliLTRiZWQtOTljZS0xMjYzNjVhY2UwMDEiLCJpYXQiOjE0OTU0NDQyNjIsImV4cCI6MjEyNjE2NDI2MiwiYXVkIjoiYXBpIiwidmVyIjoiMS4wLjAiLCJzY29wZSI6IltcInB1YmxpY2FwaVwiXSJ9.j0ZBPCECPkmYJFlaGyPa5tYueL-btKPoJD6XSCPd8YMol469E59EA_Yy5mvW9moHl93scGuOAHy_afpJ15XB0g",
  baseImageUrl: "https://imagecdn.prompto.com",
  cdnUrl: "",
  entityObjectId: "aroundmedia",
  braintreeTokenizationKey: "production_kfpzmtxx_q9y2pd3pqrhq9m96",
  applicationDomain: ".prompto.com",
  applicationUrl: "app.prompto.com",
  sessionTokenRenewTimeout: 60 * 120 * 1e3,
  googleStorageBucketId: "assetsvxgi.vr-tual.media",
  googleMapsAPIKey: "AIzaSyC__OLtuhUjX4al5F6humPLtvZxlnYiyoQ",
  trialSubscriptionPlanId: "around-media-trial-custom",
  defaultAppExpiration: "20m",
  stripe: "pk_live_iHL3y8Hs76Rc76GB6E5QI58A",
  zippopotamusAPI: "https://api.zippopotam.us/",
  typeFormOrderProjectUrl: "https://prompto.typeform.com/to/Y3aUSL",
  orderSceneDismissCookiePopupTimeout: 7 * 24 * 60 * 60 * 1e3,
  //1 week
  orderSceneDismissCookieName: "orderSceneMaybeLater",
  loggedOutCookieName: "loggedOut",
  aroundMediaPortalUrl: "https://360app.prompto.com",
  aroundMediaViewerlUrl: "https://360.prompto.com",
  hubspotPortalId: "4943441",
  upsell360FormId: "cb83198e-2a84-4898-85c0-a2aa4bb78ee1",
  upsellSessionTokenRenewThreshold: 4,
  essentialPackageInfo: "https://prompto.com/subscriptions-essential/",
  defaultMaxProjectCount: "1",
  defaultMaxUserCount: "2",
  showcaseUrl: "https://showcase.prompto.com",
  supportBaseLink: "https://support.prompto.com",
  supportBillingPerUnitLink: "https://support.prompto.com/billing-per-unit",
  supportPromptoPortalLink: "https://support.prompto.com/prompto-portal",
  base360ImageUrl: "https://imagecdn.around.media",
  base360Url: "https://api.around.media/v1",
  termsOfService: "https://prompto.com/terms-of-service/",
  privacyPolicy: "https://prompto.com/privacy-policy-2/",
  apiToken360: "eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI0NDRmMjBhZi04NWFiLTQzYWEtOTJkOC1lZTk1ZWIyYzVkM2QiLCJpYXQiOjE0Njk1MjAzMTMsImV4cCI6MjEwMDI0MDMxMywiYXVkIjoidmlld2VyIiwidmVyIjoiMS4wLjAiLCJzY29wZSI6IltcInB1YmxpY3ZpZXdlclwiXSJ9.DLrC1mSZGo0kl2eVPd5iJEpfFo8UBaiD6gGF-5pn9yec7XnN9QOvCe_NoiltCMjQaLK3Dy7F4j27oCXn-0sBBg",
  cloudinaryVideoBaseUrl: "https://res.cloudinary.com/prompto/video/upload/",
  cloudinaryImageBaseUrl: "https://res.cloudinary.com/prompto/image/upload/",
  cloudinaryCloudName: "prompto",
  contractTestSigningCookieName: "contractTestSigningCookie",
  appcueFlowWelcomeToPrompto: "-M5gKZ74LwcJvNfA-RUV",
  appcueFlowFirstProjectCreation: "47f26f52-5f82-4d0c-bf77-39a53486eb8e",
  appcueFlowUnitTypes: "36874fad-1be5-4548-ac8c-c914b359b5bc",
  appcueFlowFieldBuilder: "784b606c-629a-49d5-b6d0-f6768963ee2c",
  productLedRegistrationHubspotPortalId: "4943441",
  productLedRegistrationHubspotFormId: "2cc12361-7323-4a0b-86bd-073d2ebb1e40",
  howToSetupUnitTypes: "https://support.prompto.com/billing-per-unit#per-unit-billing-model",
  howToImportUnitsFromCSV: "https://support.prompto.com/how-to-import-units-to-the-showcase-from-csv-file"
};
var cn = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function de(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var xe = {}, sn = (e) => encodeURIComponent(e).replace(/[!'()*]/g, (n) => `%${n.charCodeAt(0).toString(16).toUpperCase()}`), Ve = "%[a-f0-9]{2}", he = new RegExp("(" + Ve + ")|([^%]+?)", "gi"), fe = new RegExp("(" + Ve + ")+", "gi");
function le(e, n) {
  try {
    return [decodeURIComponent(e.join(""))];
  } catch {
  }
  if (e.length === 1)
    return e;
  n = n || 1;
  var a = e.slice(0, n), t = e.slice(n);
  return Array.prototype.concat.call([], le(a), le(t));
}
function un(e) {
  try {
    return decodeURIComponent(e);
  } catch {
    for (var n = e.match(he) || [], a = 1; a < n.length; a++)
      e = le(n, a).join(""), n = e.match(he) || [];
    return e;
  }
}
function ln(e) {
  for (var n = {
    "%FE%FF": "��",
    "%FF%FE": "��"
  }, a = fe.exec(e); a; ) {
    try {
      n[a[0]] = decodeURIComponent(a[0]);
    } catch {
      var t = un(a[0]);
      t !== a[0] && (n[a[0]] = t);
    }
    a = fe.exec(e);
  }
  n["%C2"] = "�";
  for (var r = Object.keys(n), c = 0; c < r.length; c++) {
    var g = r[c];
    e = e.replace(new RegExp(g, "g"), n[g]);
  }
  return e;
}
var pn = function(e) {
  if (typeof e != "string")
    throw new TypeError("Expected `encodedURI` to be of type `string`, got `" + typeof e + "`");
  try {
    return e = e.replace(/\+/g, " "), decodeURIComponent(e);
  } catch {
    return ln(e);
  }
}, gn = (e, n) => {
  if (!(typeof e == "string" && typeof n == "string"))
    throw new TypeError("Expected the arguments to be of type `string`");
  if (n === "")
    return [e];
  const a = e.indexOf(n);
  return a === -1 ? [e] : [
    e.slice(0, a),
    e.slice(a + n.length)
  ];
}, dn = function(e, n) {
  for (var a = {}, t = Object.keys(e), r = Array.isArray(n), c = 0; c < t.length; c++) {
    var g = t[c], S = e[g];
    (r ? n.indexOf(g) !== -1 : n(g, S, e)) && (a[g] = S);
  }
  return a;
};
(function(e) {
  const n = sn, a = pn, t = gn, r = dn, c = (i) => i == null;
  function g(i) {
    switch (i.arrayFormat) {
      case "index":
        return (s) => (p, u) => {
          const h = p.length;
          return u === void 0 || i.skipNull && u === null || i.skipEmptyString && u === "" ? p : u === null ? [...p, [v(s, i), "[", h, "]"].join("")] : [
            ...p,
            [v(s, i), "[", v(h, i), "]=", v(u, i)].join("")
          ];
        };
      case "bracket":
        return (s) => (p, u) => u === void 0 || i.skipNull && u === null || i.skipEmptyString && u === "" ? p : u === null ? [...p, [v(s, i), "[]"].join("")] : [...p, [v(s, i), "[]=", v(u, i)].join("")];
      case "comma":
      case "separator":
        return (s) => (p, u) => u == null || u.length === 0 ? p : p.length === 0 ? [[v(s, i), "=", v(u, i)].join("")] : [[p, v(u, i)].join(i.arrayFormatSeparator)];
      default:
        return (s) => (p, u) => u === void 0 || i.skipNull && u === null || i.skipEmptyString && u === "" ? p : u === null ? [...p, v(s, i)] : [...p, [v(s, i), "=", v(u, i)].join("")];
    }
  }
  function S(i) {
    let s;
    switch (i.arrayFormat) {
      case "index":
        return (p, u, h) => {
          if (s = /\[(\d*)\]$/.exec(p), p = p.replace(/\[\d*\]$/, ""), !s) {
            h[p] = u;
            return;
          }
          h[p] === void 0 && (h[p] = {}), h[p][s[1]] = u;
        };
      case "bracket":
        return (p, u, h) => {
          if (s = /(\[\])$/.exec(p), p = p.replace(/\[\]$/, ""), !s) {
            h[p] = u;
            return;
          }
          if (h[p] === void 0) {
            h[p] = [u];
            return;
          }
          h[p] = [].concat(h[p], u);
        };
      case "comma":
      case "separator":
        return (p, u, h) => {
          const U = typeof u == "string" && u.includes(i.arrayFormatSeparator), A = typeof u == "string" && !U && f(u, i).includes(i.arrayFormatSeparator);
          u = A ? f(u, i) : u;
          const k = U || A ? u.split(i.arrayFormatSeparator).map((rn) => f(rn, i)) : u === null ? u : f(u, i);
          h[p] = k;
        };
      default:
        return (p, u, h) => {
          if (h[p] === void 0) {
            h[p] = u;
            return;
          }
          h[p] = [].concat(h[p], u);
        };
    }
  }
  function d(i) {
    if (typeof i != "string" || i.length !== 1)
      throw new TypeError("arrayFormatSeparator must be single character string");
  }
  function v(i, s) {
    return s.encode ? s.strict ? n(i) : encodeURIComponent(i) : i;
  }
  function f(i, s) {
    return s.decode ? a(i) : i;
  }
  function b(i) {
    return Array.isArray(i) ? i.sort() : typeof i == "object" ? b(Object.keys(i)).sort((s, p) => Number(s) - Number(p)).map((s) => i[s]) : i;
  }
  function R(i) {
    const s = i.indexOf("#");
    return s !== -1 && (i = i.slice(0, s)), i;
  }
  function M(i) {
    let s = "";
    const p = i.indexOf("#");
    return p !== -1 && (s = i.slice(p)), s;
  }
  function y(i) {
    i = R(i);
    const s = i.indexOf("?");
    return s === -1 ? "" : i.slice(s + 1);
  }
  function D(i, s) {
    return s.parseNumbers && !Number.isNaN(Number(i)) && typeof i == "string" && i.trim() !== "" ? i = Number(i) : s.parseBooleans && i !== null && (i.toLowerCase() === "true" || i.toLowerCase() === "false") && (i = i.toLowerCase() === "true"), i;
  }
  function T(i, s) {
    s = Object.assign({
      decode: !0,
      sort: !0,
      arrayFormat: "none",
      arrayFormatSeparator: ",",
      parseNumbers: !1,
      parseBooleans: !1
    }, s), d(s.arrayFormatSeparator);
    const p = S(s), u = /* @__PURE__ */ Object.create(null);
    if (typeof i != "string" || (i = i.trim().replace(/^[?#&]/, ""), !i))
      return u;
    for (const h of i.split("&")) {
      if (h === "")
        continue;
      let [U, A] = t(s.decode ? h.replace(/\+/g, " ") : h, "=");
      A = A === void 0 ? null : ["comma", "separator"].includes(s.arrayFormat) ? A : f(A, s), p(f(U, s), A, u);
    }
    for (const h of Object.keys(u)) {
      const U = u[h];
      if (typeof U == "object" && U !== null)
        for (const A of Object.keys(U))
          U[A] = D(U[A], s);
      else
        u[h] = D(U, s);
    }
    return s.sort === !1 ? u : (s.sort === !0 ? Object.keys(u).sort() : Object.keys(u).sort(s.sort)).reduce((h, U) => {
      const A = u[U];
      return A && typeof A == "object" && !Array.isArray(A) ? h[U] = b(A) : h[U] = A, h;
    }, /* @__PURE__ */ Object.create(null));
  }
  e.extract = y, e.parse = T, e.stringify = (i, s) => {
    if (!i)
      return "";
    s = Object.assign({
      encode: !0,
      strict: !0,
      arrayFormat: "none",
      arrayFormatSeparator: ","
    }, s), d(s.arrayFormatSeparator);
    const p = (A) => s.skipNull && c(i[A]) || s.skipEmptyString && i[A] === "", u = g(s), h = {};
    for (const A of Object.keys(i))
      p(A) || (h[A] = i[A]);
    const U = Object.keys(h);
    return s.sort !== !1 && U.sort(s.sort), U.map((A) => {
      const k = i[A];
      return k === void 0 ? "" : k === null ? v(A, s) : Array.isArray(k) ? k.reduce(u(A), []).join("&") : v(A, s) + "=" + v(k, s);
    }).filter((A) => A.length > 0).join("&");
  }, e.parseUrl = (i, s) => {
    s = Object.assign({
      decode: !0
    }, s);
    const [p, u] = t(i, "#");
    return Object.assign(
      {
        url: p.split("?")[0] || "",
        query: T(y(i), s)
      },
      s && s.parseFragmentIdentifier && u ? { fragmentIdentifier: f(u, s) } : {}
    );
  }, e.stringifyUrl = (i, s) => {
    s = Object.assign({
      encode: !0,
      strict: !0
    }, s);
    const p = R(i.url).split("?")[0] || "", u = e.extract(i.url), h = e.parse(u, { sort: !1 }), U = Object.assign(h, i.query);
    let A = e.stringify(U, s);
    A && (A = `?${A}`);
    let k = M(i.url);
    return i.fragmentIdentifier && (k = `#${v(i.fragmentIdentifier, s)}`), `${p}${A}${k}`;
  }, e.pick = (i, s, p) => {
    p = Object.assign({
      parseFragmentIdentifier: !0
    }, p);
    const { url: u, query: h, fragmentIdentifier: U } = e.parseUrl(i, p);
    return e.stringifyUrl({
      url: u,
      query: r(h, s),
      fragmentIdentifier: U
    }, p);
  }, e.exclude = (i, s, p) => {
    const u = Array.isArray(s) ? (h) => !s.includes(h) : (h, U) => !s(h, U);
    return e.pick(i, u, p);
  };
})(xe);
const He = /* @__PURE__ */ de(xe), O = (e) => {
  const n = window.location.host.match(/localhost/g) || window.location.hostname.match(
    /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  ), { env: a } = He.parse(window.location.search.substring(1));
  return (e || a) && (e === "production" || a === "production") ? on : Y;
};
var me = { exports: {} }, qe = function(n, a) {
  return function() {
    for (var r = new Array(arguments.length), c = 0; c < r.length; c++)
      r[c] = arguments[c];
    return n.apply(a, r);
  };
}, mn = qe, B = Object.prototype.toString;
function Ke(e) {
  return B.call(e) === "[object Array]";
}
function pe(e) {
  return typeof e > "u";
}
function hn(e) {
  return e !== null && !pe(e) && e.constructor !== null && !pe(e.constructor) && typeof e.constructor.isBuffer == "function" && e.constructor.isBuffer(e);
}
function fn(e) {
  return B.call(e) === "[object ArrayBuffer]";
}
function vn(e) {
  return typeof FormData < "u" && e instanceof FormData;
}
function yn(e) {
  var n;
  return typeof ArrayBuffer < "u" && ArrayBuffer.isView ? n = ArrayBuffer.isView(e) : n = e && e.buffer && e.buffer instanceof ArrayBuffer, n;
}
function Sn(e) {
  return typeof e == "string";
}
function An(e) {
  return typeof e == "number";
}
function Ye(e) {
  return e !== null && typeof e == "object";
}
function Un(e) {
  return B.call(e) === "[object Date]";
}
function bn(e) {
  return B.call(e) === "[object File]";
}
function Cn(e) {
  return B.call(e) === "[object Blob]";
}
function Je(e) {
  return B.call(e) === "[object Function]";
}
function Pn(e) {
  return Ye(e) && Je(e.pipe);
}
function kn(e) {
  return typeof URLSearchParams < "u" && e instanceof URLSearchParams;
}
function En(e) {
  return e.replace(/^\s*/, "").replace(/\s*$/, "");
}
function wn() {
  return typeof navigator < "u" && (navigator.product === "ReactNative" || navigator.product === "NativeScript" || navigator.product === "NS") ? !1 : typeof window < "u" && typeof document < "u";
}
function x(e, n) {
  if (!(e === null || typeof e > "u"))
    if (typeof e != "object" && (e = [e]), Ke(e))
      for (var a = 0, t = e.length; a < t; a++)
        n.call(null, e[a], a, e);
    else
      for (var r in e)
        Object.prototype.hasOwnProperty.call(e, r) && n.call(null, e[r], r, e);
}
function ze() {
  var e = {};
  function n(r, c) {
    typeof e[c] == "object" && typeof r == "object" ? e[c] = ze(e[c], r) : e[c] = r;
  }
  for (var a = 0, t = arguments.length; a < t; a++)
    x(arguments[a], n);
  return e;
}
function ge() {
  var e = {};
  function n(r, c) {
    typeof e[c] == "object" && typeof r == "object" ? e[c] = ge(e[c], r) : typeof r == "object" ? e[c] = ge({}, r) : e[c] = r;
  }
  for (var a = 0, t = arguments.length; a < t; a++)
    x(arguments[a], n);
  return e;
}
function Rn(e, n, a) {
  return x(n, function(r, c) {
    a && typeof r == "function" ? e[c] = mn(r, a) : e[c] = r;
  }), e;
}
var P = {
  isArray: Ke,
  isArrayBuffer: fn,
  isBuffer: hn,
  isFormData: vn,
  isArrayBufferView: yn,
  isString: Sn,
  isNumber: An,
  isObject: Ye,
  isUndefined: pe,
  isDate: Un,
  isFile: bn,
  isBlob: Cn,
  isFunction: Je,
  isStream: Pn,
  isURLSearchParams: kn,
  isStandardBrowserEnv: wn,
  forEach: x,
  merge: ze,
  deepMerge: ge,
  extend: Rn,
  trim: En
}, I = P;
function ve(e) {
  return encodeURIComponent(e).replace(/%40/gi, "@").replace(/%3A/gi, ":").replace(/%24/g, "$").replace(/%2C/gi, ",").replace(/%20/g, "+").replace(/%5B/gi, "[").replace(/%5D/gi, "]");
}
var Xe = function(n, a, t) {
  if (!a)
    return n;
  var r;
  if (t)
    r = t(a);
  else if (I.isURLSearchParams(a))
    r = a.toString();
  else {
    var c = [];
    I.forEach(a, function(d, v) {
      d === null || typeof d > "u" || (I.isArray(d) ? v = v + "[]" : d = [d], I.forEach(d, function(b) {
        I.isDate(b) ? b = b.toISOString() : I.isObject(b) && (b = JSON.stringify(b)), c.push(ve(v) + "=" + ve(b));
      }));
    }), r = c.join("&");
  }
  if (r) {
    var g = n.indexOf("#");
    g !== -1 && (n = n.slice(0, g)), n += (n.indexOf("?") === -1 ? "?" : "&") + r;
  }
  return n;
}, Fn = P;
function V() {
  this.handlers = [];
}
V.prototype.use = function(n, a) {
  return this.handlers.push({
    fulfilled: n,
    rejected: a
  }), this.handlers.length - 1;
};
V.prototype.eject = function(n) {
  this.handlers[n] && (this.handlers[n] = null);
};
V.prototype.forEach = function(n) {
  Fn.forEach(this.handlers, function(t) {
    t !== null && n(t);
  });
};
var Mn = V, In = P, On = function(n, a, t) {
  return In.forEach(t, function(c) {
    n = c(n, a);
  }), n;
}, J, ye;
function Ze() {
  return ye || (ye = 1, J = function(n) {
    return !!(n && n.__CANCEL__);
  }), J;
}
var Bn = P, $n = function(n, a) {
  Bn.forEach(n, function(r, c) {
    c !== a && c.toUpperCase() === a.toUpperCase() && (n[a] = r, delete n[c]);
  });
}, z, Se;
function Nn() {
  return Se || (Se = 1, z = function(n, a, t, r, c) {
    return n.config = a, t && (n.code = t), n.request = r, n.response = c, n.isAxiosError = !0, n.toJSON = function() {
      return {
        // Standard
        message: this.message,
        name: this.name,
        // Microsoft
        description: this.description,
        number: this.number,
        // Mozilla
        fileName: this.fileName,
        lineNumber: this.lineNumber,
        columnNumber: this.columnNumber,
        stack: this.stack,
        // Axios
        config: this.config,
        code: this.code
      };
    }, n;
  }), z;
}
var X, Ae;
function _e() {
  if (Ae)
    return X;
  Ae = 1;
  var e = Nn();
  return X = function(a, t, r, c, g) {
    var S = new Error(a);
    return e(S, t, r, c, g);
  }, X;
}
var Z, Ue;
function Dn() {
  if (Ue)
    return Z;
  Ue = 1;
  var e = _e();
  return Z = function(a, t, r) {
    var c = r.config.validateStatus;
    !c || c(r.status) ? a(r) : t(e(
      "Request failed with status code " + r.status,
      r.config,
      null,
      r.request,
      r
    ));
  }, Z;
}
var _, be;
function Tn() {
  return be || (be = 1, _ = function(n) {
    return /^([a-z][a-z\d\+\-\.]*:)?\/\//i.test(n);
  }), _;
}
var W, Ce;
function Ln() {
  return Ce || (Ce = 1, W = function(n, a) {
    return a ? n.replace(/\/+$/, "") + "/" + a.replace(/^\/+/, "") : n;
  }), W;
}
var Q, Pe;
function jn() {
  if (Pe)
    return Q;
  Pe = 1;
  var e = Tn(), n = Ln();
  return Q = function(t, r) {
    return t && !e(r) ? n(t, r) : r;
  }, Q;
}
var ee, ke;
function Gn() {
  if (ke)
    return ee;
  ke = 1;
  var e = P, n = [
    "age",
    "authorization",
    "content-length",
    "content-type",
    "etag",
    "expires",
    "from",
    "host",
    "if-modified-since",
    "if-unmodified-since",
    "last-modified",
    "location",
    "max-forwards",
    "proxy-authorization",
    "referer",
    "retry-after",
    "user-agent"
  ];
  return ee = function(t) {
    var r = {}, c, g, S;
    return t && e.forEach(t.split(`
`), function(v) {
      if (S = v.indexOf(":"), c = e.trim(v.substr(0, S)).toLowerCase(), g = e.trim(v.substr(S + 1)), c) {
        if (r[c] && n.indexOf(c) >= 0)
          return;
        c === "set-cookie" ? r[c] = (r[c] ? r[c] : []).concat([g]) : r[c] = r[c] ? r[c] + ", " + g : g;
      }
    }), r;
  }, ee;
}
var ne, Ee;
function xn() {
  if (Ee)
    return ne;
  Ee = 1;
  var e = P;
  return ne = e.isStandardBrowserEnv() ? (
    // Standard browser envs have full support of the APIs needed to test
    // whether the request URL is of the same origin as current location.
    function() {
      var a = /(msie|trident)/i.test(navigator.userAgent), t = document.createElement("a"), r;
      function c(g) {
        var S = g;
        return a && (t.setAttribute("href", S), S = t.href), t.setAttribute("href", S), {
          href: t.href,
          protocol: t.protocol ? t.protocol.replace(/:$/, "") : "",
          host: t.host,
          search: t.search ? t.search.replace(/^\?/, "") : "",
          hash: t.hash ? t.hash.replace(/^#/, "") : "",
          hostname: t.hostname,
          port: t.port,
          pathname: t.pathname.charAt(0) === "/" ? t.pathname : "/" + t.pathname
        };
      }
      return r = c(window.location.href), function(S) {
        var d = e.isString(S) ? c(S) : S;
        return d.protocol === r.protocol && d.host === r.host;
      };
    }()
  ) : (
    // Non standard browser envs (web workers, react-native) lack needed support.
    function() {
      return function() {
        return !0;
      };
    }()
  ), ne;
}
var ae, we;
function Vn() {
  if (we)
    return ae;
  we = 1;
  var e = P;
  return ae = e.isStandardBrowserEnv() ? (
    // Standard browser envs support document.cookie
    function() {
      return {
        write: function(t, r, c, g, S, d) {
          var v = [];
          v.push(t + "=" + encodeURIComponent(r)), e.isNumber(c) && v.push("expires=" + new Date(c).toGMTString()), e.isString(g) && v.push("path=" + g), e.isString(S) && v.push("domain=" + S), d === !0 && v.push("secure"), document.cookie = v.join("; ");
        },
        read: function(t) {
          var r = document.cookie.match(new RegExp("(^|;\\s*)(" + t + ")=([^;]*)"));
          return r ? decodeURIComponent(r[3]) : null;
        },
        remove: function(t) {
          this.write(t, "", Date.now() - 864e5);
        }
      };
    }()
  ) : (
    // Non standard browser env (web workers, react-native) lack needed support.
    function() {
      return {
        write: function() {
        },
        read: function() {
          return null;
        },
        remove: function() {
        }
      };
    }()
  ), ae;
}
var te, Re;
function Fe() {
  if (Re)
    return te;
  Re = 1;
  var e = P, n = Dn(), a = Xe, t = jn(), r = Gn(), c = xn(), g = _e();
  return te = function(d) {
    return new Promise(function(f, b) {
      var R = d.data, M = d.headers;
      e.isFormData(R) && delete M["Content-Type"];
      var y = new XMLHttpRequest();
      if (d.auth) {
        var D = d.auth.username || "", T = d.auth.password || "";
        M.Authorization = "Basic " + btoa(D + ":" + T);
      }
      var i = t(d.baseURL, d.url);
      if (y.open(d.method.toUpperCase(), a(i, d.params, d.paramsSerializer), !0), y.timeout = d.timeout, y.onreadystatechange = function() {
        if (!(!y || y.readyState !== 4) && !(y.status === 0 && !(y.responseURL && y.responseURL.indexOf("file:") === 0))) {
          var h = "getAllResponseHeaders" in y ? r(y.getAllResponseHeaders()) : null, U = !d.responseType || d.responseType === "text" ? y.responseText : y.response, A = {
            data: U,
            status: y.status,
            statusText: y.statusText,
            headers: h,
            config: d,
            request: y
          };
          n(f, b, A), y = null;
        }
      }, y.onabort = function() {
        y && (b(g("Request aborted", d, "ECONNABORTED", y)), y = null);
      }, y.onerror = function() {
        b(g("Network Error", d, null, y)), y = null;
      }, y.ontimeout = function() {
        var h = "timeout of " + d.timeout + "ms exceeded";
        d.timeoutErrorMessage && (h = d.timeoutErrorMessage), b(g(
          h,
          d,
          "ECONNABORTED",
          y
        )), y = null;
      }, e.isStandardBrowserEnv()) {
        var s = Vn(), p = (d.withCredentials || c(i)) && d.xsrfCookieName ? s.read(d.xsrfCookieName) : void 0;
        p && (M[d.xsrfHeaderName] = p);
      }
      if ("setRequestHeader" in y && e.forEach(M, function(h, U) {
        typeof R > "u" && U.toLowerCase() === "content-type" ? delete M[U] : y.setRequestHeader(U, h);
      }), e.isUndefined(d.withCredentials) || (y.withCredentials = !!d.withCredentials), d.responseType)
        try {
          y.responseType = d.responseType;
        } catch (u) {
          if (d.responseType !== "json")
            throw u;
        }
      typeof d.onDownloadProgress == "function" && y.addEventListener("progress", d.onDownloadProgress), typeof d.onUploadProgress == "function" && y.upload && y.upload.addEventListener("progress", d.onUploadProgress), d.cancelToken && d.cancelToken.promise.then(function(h) {
        y && (y.abort(), b(h), y = null);
      }), R === void 0 && (R = null), y.send(R);
    });
  }, te;
}
var C = P, Me = $n, Hn = {
  "Content-Type": "application/x-www-form-urlencoded"
};
function Ie(e, n) {
  !C.isUndefined(e) && C.isUndefined(e["Content-Type"]) && (e["Content-Type"] = n);
}
function qn() {
  var e;
  return (typeof XMLHttpRequest < "u" || typeof process < "u" && Object.prototype.toString.call(process) === "[object process]") && (e = Fe()), e;
}
var H = {
  adapter: qn(),
  transformRequest: [function(n, a) {
    return Me(a, "Accept"), Me(a, "Content-Type"), C.isFormData(n) || C.isArrayBuffer(n) || C.isBuffer(n) || C.isStream(n) || C.isFile(n) || C.isBlob(n) ? n : C.isArrayBufferView(n) ? n.buffer : C.isURLSearchParams(n) ? (Ie(a, "application/x-www-form-urlencoded;charset=utf-8"), n.toString()) : C.isObject(n) ? (Ie(a, "application/json;charset=utf-8"), JSON.stringify(n)) : n;
  }],
  transformResponse: [function(n) {
    if (typeof n == "string")
      try {
        n = JSON.parse(n);
      } catch {
      }
    return n;
  }],
  /**
   * A timeout in milliseconds to abort a request. If set to 0 (default) a
   * timeout is not created.
   */
  timeout: 0,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  maxContentLength: -1,
  validateStatus: function(n) {
    return n >= 200 && n < 300;
  }
};
H.headers = {
  common: {
    Accept: "application/json, text/plain, */*"
  }
};
C.forEach(["delete", "get", "head"], function(n) {
  H.headers[n] = {};
});
C.forEach(["post", "put", "patch"], function(n) {
  H.headers[n] = C.merge(Hn);
});
var We = H, Oe = P, re = On, Kn = Ze(), Yn = We;
function ie(e) {
  e.cancelToken && e.cancelToken.throwIfRequested();
}
var Jn = function(n) {
  ie(n), n.headers = n.headers || {}, n.data = re(
    n.data,
    n.headers,
    n.transformRequest
  ), n.headers = Oe.merge(
    n.headers.common || {},
    n.headers[n.method] || {},
    n.headers
  ), Oe.forEach(
    ["delete", "get", "head", "post", "put", "patch", "common"],
    function(r) {
      delete n.headers[r];
    }
  );
  var a = n.adapter || Yn.adapter;
  return a(n).then(function(r) {
    return ie(n), r.data = re(
      r.data,
      r.headers,
      n.transformResponse
    ), r;
  }, function(r) {
    return Kn(r) || (ie(n), r && r.response && (r.response.data = re(
      r.response.data,
      r.response.headers,
      n.transformResponse
    ))), Promise.reject(r);
  });
}, F = P, Qe = function(n, a) {
  a = a || {};
  var t = {}, r = ["url", "method", "params", "data"], c = ["headers", "auth", "proxy"], g = [
    "baseURL",
    "url",
    "transformRequest",
    "transformResponse",
    "paramsSerializer",
    "timeout",
    "withCredentials",
    "adapter",
    "responseType",
    "xsrfCookieName",
    "xsrfHeaderName",
    "onUploadProgress",
    "onDownloadProgress",
    "maxContentLength",
    "validateStatus",
    "maxRedirects",
    "httpAgent",
    "httpsAgent",
    "cancelToken",
    "socketPath"
  ];
  F.forEach(r, function(f) {
    typeof a[f] < "u" && (t[f] = a[f]);
  }), F.forEach(c, function(f) {
    F.isObject(a[f]) ? t[f] = F.deepMerge(n[f], a[f]) : typeof a[f] < "u" ? t[f] = a[f] : F.isObject(n[f]) ? t[f] = F.deepMerge(n[f]) : typeof n[f] < "u" && (t[f] = n[f]);
  }), F.forEach(g, function(f) {
    typeof a[f] < "u" ? t[f] = a[f] : typeof n[f] < "u" && (t[f] = n[f]);
  });
  var S = r.concat(c).concat(g), d = Object.keys(a).filter(function(f) {
    return S.indexOf(f) === -1;
  });
  return F.forEach(d, function(f) {
    typeof a[f] < "u" ? t[f] = a[f] : typeof n[f] < "u" && (t[f] = n[f]);
  }), t;
}, G = P, zn = Xe, Be = Mn, Xn = Jn, en = Qe;
function $(e) {
  this.defaults = e, this.interceptors = {
    request: new Be(),
    response: new Be()
  };
}
$.prototype.request = function(n) {
  typeof n == "string" ? (n = arguments[1] || {}, n.url = arguments[0]) : n = n || {}, n = en(this.defaults, n), n.method ? n.method = n.method.toLowerCase() : this.defaults.method ? n.method = this.defaults.method.toLowerCase() : n.method = "get";
  var a = [Xn, void 0], t = Promise.resolve(n);
  for (this.interceptors.request.forEach(function(c) {
    a.unshift(c.fulfilled, c.rejected);
  }), this.interceptors.response.forEach(function(c) {
    a.push(c.fulfilled, c.rejected);
  }); a.length; )
    t = t.then(a.shift(), a.shift());
  return t;
};
$.prototype.getUri = function(n) {
  return n = en(this.defaults, n), zn(n.url, n.params, n.paramsSerializer).replace(/^\?/, "");
};
G.forEach(["delete", "get", "head", "options"], function(n) {
  $.prototype[n] = function(a, t) {
    return this.request(G.merge(t || {}, {
      method: n,
      url: a
    }));
  };
});
G.forEach(["post", "put", "patch"], function(n) {
  $.prototype[n] = function(a, t, r) {
    return this.request(G.merge(r || {}, {
      method: n,
      url: a,
      data: t
    }));
  };
});
var Zn = $, oe, $e;
function nn() {
  if ($e)
    return oe;
  $e = 1;
  function e(n) {
    this.message = n;
  }
  return e.prototype.toString = function() {
    return "Cancel" + (this.message ? ": " + this.message : "");
  }, e.prototype.__CANCEL__ = !0, oe = e, oe;
}
var ce, Ne;
function _n() {
  if (Ne)
    return ce;
  Ne = 1;
  var e = nn();
  function n(a) {
    if (typeof a != "function")
      throw new TypeError("executor must be a function.");
    var t;
    this.promise = new Promise(function(g) {
      t = g;
    });
    var r = this;
    a(function(g) {
      r.reason || (r.reason = new e(g), t(r.reason));
    });
  }
  return n.prototype.throwIfRequested = function() {
    if (this.reason)
      throw this.reason;
  }, n.source = function() {
    var t, r = new n(function(g) {
      t = g;
    });
    return {
      token: r,
      cancel: t
    };
  }, ce = n, ce;
}
var se, De;
function Wn() {
  return De || (De = 1, se = function(n) {
    return function(t) {
      return n.apply(null, t);
    };
  }), se;
}
var Te = P, Qn = qe, j = Zn, ea = Qe, na = We;
function an(e) {
  var n = new j(e), a = Qn(j.prototype.request, n);
  return Te.extend(a, j.prototype, n), Te.extend(a, n), a;
}
var w = an(na);
w.Axios = j;
w.create = function(n) {
  return an(ea(w.defaults, n));
};
w.Cancel = nn();
w.CancelToken = _n();
w.isCancel = Ze();
w.all = function(n) {
  return Promise.all(n);
};
w.spread = Wn();
me.exports = w;
me.exports.default = w;
var aa = me.exports, ta = aa;
const N = /* @__PURE__ */ de(ta);
var tn = { exports: {} };
(function(e) {
  (function(n, a, t) {
    e.exports ? e.exports = t() : a[n] = t();
  })("urljoin", cn, function() {
    function n(a) {
      var t = [];
      if (a.length === 0)
        return "";
      if (typeof a[0] != "string")
        throw new TypeError("Url must be a string. Received " + a[0]);
      if (a[0].match(/^[^/:]+:\/*$/) && a.length > 1) {
        var r = a.shift();
        a[0] = r + a[0];
      }
      a[0].match(/^file:\/\/\//) ? a[0] = a[0].replace(/^([^/:]+):\/*/, "$1:///") : a[0] = a[0].replace(/^([^/:]+):\/*/, "$1://");
      for (var c = 0; c < a.length; c++) {
        var g = a[c];
        if (typeof g != "string")
          throw new TypeError("Url must be a string. Received " + g);
        g !== "" && (c > 0 && (g = g.replace(/^[\/]+/, "")), c < a.length - 1 ? g = g.replace(/[\/]+$/, "") : g = g.replace(/[\/]+$/, "/"), t.push(g));
      }
      var S = t.join("/");
      S = S.replace(/\/(\?|&|#[^!])/g, "$1");
      var d = S.split("?");
      return S = d.shift() + (d.length > 0 ? "?" : "") + d.join("&"), S;
    }
    return function() {
      var a;
      return typeof arguments[0] == "object" ? a = arguments[0] : a = [].slice.call(arguments), n(a);
    };
  });
})(tn);
var ra = tn.exports;
const q = /* @__PURE__ */ de(ra), K = (e) => {
  const n = { "X-Around-Api-Token": O().apiToken };
  return e && (n["X-Around-Session-Token"] = e), n;
}, m = (e, n, a) => N.post(
  q(O().baseUrl, e, `?_=${Date.now()}`),
  n,
  {
    headers: K(a)
  }
), o = (e, n, a) => N.get(q(O().baseUrl, e, `?_=${Date.now()}`), {
  params: n,
  paramsSerializer: function(t) {
    return He.stringify(t, { arrayFormat: "none" });
  },
  headers: K(a)
}), l = (e, n, a) => N.put(
  q(O().baseUrl, e, `?_=${Date.now()}`),
  n,
  {
    headers: K(a)
  }
), E = (e, n) => N.delete(q(O().baseUrl, e, `?_=${Date.now()}`), {
  headers: K(n)
});
var ia = /* @__PURE__ */ ((e) => (e.active = "active", e.archived = "archived", e))(ia || {});
const ue = O(), ka = {
  /**
   * Get the total visits per sharecode of a project, including the last activity on the sharecode
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_gettotalvisitspersharecodewithrecentactivity
   */
  getTotalVisitsPerSharecodeWithRecentActivity: (e) => {
    const { sessionToken: n, ...a } = e;
    return o(
      "analytics/insights/getTotalVisitsPerSharecodeWithRecentActivity",
      a,
      n
    );
  },
  /**
   * Get the unique visits per sharecode
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getUniqueVisitsPerSharecode
   */
  getUniqueVisitsPerSharecode: (e) => {
    const { sessionToken: n, ...a } = e;
    return o(
      "analytics/insights/getUniqueVisitsPerSharecode",
      a,
      n
    );
  },
  /**
   * Get the visit duration per sharecode
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getAllPromptoSessionsPerSharecode
   */
  getAllPromptoSessionsPerSharecode: (e) => {
    const { sessionToken: n, ...a } = e;
    return o(
      "analytics/insights/getAllPromptoSessionsPerSharecode",
      a,
      n
    );
  },
  /**
   * Get the total visits per city
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getTotalVisitsPerCity
   */
  getTotalVisitsPerCity: (e) => {
    const { sessionToken: n, ...a } = e;
    return o(
      "analytics/insights/getTotalVisitsPerCity",
      a,
      n
    );
  },
  /**
   * Get the unique visits per city
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getUniqueVisitsPerCity
   */
  getUniqueVisitsPerCity: (e) => {
    const { sessionToken: n, ...a } = e;
    return o(
      "analytics/insights/getUniqueVisitsPerCity",
      a,
      n
    );
  },
  /**
   * Get the total visits per range
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getTotalVisitsPerRange
   */
  getTotalVisitsPerRange: (e, n) => m(
    "analytics/insights/getTotalVisitsPerRange",
    e,
    n
  ),
  /**
   * Get the uniqe visits per range
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getTotalVisitsPerRange
   */
  getUniqueVisitsPerRange: (e, n) => m(
    "analytics/insights/getUniqueVisitsPerRange",
    e,
    n
  ),
  /**
   *
   */
  getTotalVisitorActionsByNamePerUnitPerSharecode: (e) => {
    const {
      actionName: n,
      startTimestamp: a,
      endTimestamp: t,
      vaultId: r,
      projectId: c,
      filterByShareCodeId: g,
      sessionToken: S,
      returnUniqueResults: d = !1
    } = e;
    let v = {
      actionName: n,
      startTimestamp: a,
      endTimestamp: t,
      vaultId: r,
      projectId: c,
      returnUniqueResults: d
    };
    return g && (v.filterByShareCodeId = g), o(
      "analytics/insights/unit/getTotalVisitorActionsByNamePerUnitPerSharecode",
      v,
      S
    );
  },
  /**
   * Get current and total views by marketplace project
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getcurrentandtotalviewsbyproject
   */
  getCurrentAndTotalViewsByProject: (e) => o(
    "analytics/insights/project/getCurrentAndTotalViewsByProject",
    e
  ),
  /**
   * Get totalViews and lastActivity of shared content collection
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getsharedcontentcollectionactivity
   */
  getSharedContentCollectionActivity: (e) => o("analytics/sharedContentCollection/getSharedContentCollectionActivity", e),
  /**
   * Get totalViewCount, lastViewTimestamp, totalDownloadCount, lastDownloadTimestamp
   * of some particular content item
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getcontentitemactivity
   */
  getContentItemActivity: (e) => o("analytics/contentItem/getContentItemActivity", e)
}, Ea = {
  /**
   * Search the configuration sessions
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_search_2
   */
  search: (e, n, a) => o(
    `entities/${e}/configurationsessions/search`,
    n,
    a
  ),
  /**
   * Group Search the configuration sessions
   * The group search will match a search for the search value in the following fields: customer.firstName, customer.lastName, customer.phoneNumber, customer.email,project.title, unit.title
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_groupsearch
   */
  groupSearch: (e, n, a) => o(
    `entities/${e}/configurationsessions/groupSearch`,
    n,
    a
  ),
  /**
   * Get the quotation report of a session
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getquotationreport
   */
  getQuotationReport: (e, n, a) => o(
    `entities/${n}/configurationsessions/${e}/quotationreport?reportType=simpleReport`,
    {},
    a
  )
}, wa = {
  /**
   * Create a content collection
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create
   * @param {Object} params
   * @param {string} sessionToken
   */
  create: (e, n) => m("contentcollections", e, n),
  /**
   * update a contentcollection
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update
   */
  update: (e, n, a) => l(
    `contentcollections/${e}`,
    n,
    a
  ),
  /**
   * Get a content collection
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get
   */
  get: (e, n, a) => o(
    `contentcollections/${e}`,
    n,
    a
  ),
  /**
   * Get a content collection by share code
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbycode
   */
  getByCode: (e, n) => o(`contentcollections/getByCode/${e}`, {}, n),
  /**
   * Make a content collection public
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_makepublic
   */
  makePublic: (e, n) => l(
    "contentcollections/makepublic",
    { contentCollectionObjectId: e },
    n
  ),
  /**
   * Get first image and showcase configuration by code
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbycodefirstimageandshowcaseconfiguration
   */
  getFirstImageAndShowcaseConfigurationByCode: (e, n) => o(
    `contentcollections/getByCodeFirstImageAndShowcaseConfiguration/${e}`,
    {},
    n
  )
};
var oa = /* @__PURE__ */ ((e) => (e[e.image = 0] = "image", e[e.gif = 1] = "gif", e[e.video = 2] = "video", e[e.pdf = 3] = "pdf", e[e.document = 4] = "document", e[e.text = 5] = "text", e[e.mapLocation = 6] = "mapLocation", e[e.metadata = 7] = "metadata", e[e.album360 = 8] = "album360", e[e.floorplan = 9] = "floorplan", e[e.website = 10] = "website", e[e.url = 11] = "url", e[e.social = 12] = "social", e[e.iFrame = 13] = "iFrame", e[e.viewItScene = 14] = "viewItScene", e[e.scan = 15] = "scan", e[e.clean3dmodel = 16] = "clean3dmodel", e[e.image360 = 17] = "image360", e[e.bimModel = 18] = "bimModel", e[e.turntable = 19] = "turntable", e))(oa || {}), ca = /* @__PURE__ */ ((e) => (e[e.published = 0] = "published", e[e.unpublished = 1] = "unpublished", e[e.archived = 2] = "archived", e))(ca || {});
const Ra = {
  /**
   * Create a content item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_1
   */
  create: (e, n) => m("contentitems", e, n),
  /**
   * update a content item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_1
   */
  update: (e, n, a) => l(
    `contentitems/${e}`,
    n,
    a
  ),
  /**
   * Content item bulk update
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updatebulk
   * @param {Object} params
   * @param {string} sessionToken
   */
  updateBulk: (e, n) => l("contentitems/bulk", e, n),
  /**
   * Get content item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_1
   */
  get: (e, n) => o(
    `contentitems/${e}`,
    {},
    n
  ),
  /**
   * Get the history of versions of a content item
   * @param {string} contentItemId
   */
  getVersionHistory: (e, n) => o(
    `contentitemversions/getByContentItem/${e}`,
    {},
    n
  ),
  /**
   * Get the history of downloads of a content item
   * @param {string} contentItemId
   */
  getDownloadHistory: (e, n) => o(
    `contentitems/getHistoryOfDownloads/${e}`,
    {},
    n
  )
}, Fa = {
  /**
   * Create a unit import configuration
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_34
   */
  create: (e, n) => m("importunitconfiguration", e, n),
  /**
   * Get unit import configuration
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_37
   */
  get: (e, n) => o(`importunitconfiguration/${e}`, {}, n),
  /**
   * update a unit import configuration
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_32
   */
  update: (e, n, a) => l(
    `importunitconfiguration/${e}`,
    n,
    a
  )
}, Ma = {
  /**
   * Get all leads
   * Documentation is to be generated
   * @param {string} vaultId
   * @param {string} sessionToken
   */
  getAll: (e, n) => o(
    `lead/${e}`,
    { limit: 2500, state: "active" },
    n
  ),
  /**
   * Update lead
   * Documentation is to be generated
   * @param {Object} params
   * @param {string} sessionToken
   */
  update: (e, n) => l("lead", e, n),
  /**
   * Archive lead
   */
  archive: (e, n) => E(`lead/${e}`, n),
  /**
   * Create lead
   */
  create: (e) => m("lead", e),
  /**
   * Get bulk units for leads
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getbulkunitsforleads
   */
  getBulkUnitsForLeads: (e, n, a) => l(
    `vaults/${e}/units/getBulkUnitsForLeads`,
    a,
    n
  ),
  /**
   * Get prompto sessions related to specified leads
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getsessionsgroupedbyleadids
   */
  getLeadsRelatedSessions: (e, n) => l(
    "promptosession/getSessionsGroupedByLeadIds",
    n,
    e
  ),
  /**
   * Get share item codes related to specified leads
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getshareitemcodesbyleadidlist
   */
  getLeadsShareItemCodes: (e, n, a) => l(
    `shareitems/getShareItemCodesByLeadIdList/${e}`,
    a,
    n
  )
}, Ia = {
  /**
   * Create lead comment
   * Documentation is to be generated
   */
  create: (e, n) => m("lead/comment", e, n),
  /**
   * Get comment
   * Documentation is to be generated
   */
  get: (e, n) => o(`lead/comment/${e}`, {}, n),
  /**
   * Get all leads comments
   * Documentation is to be generated
   */
  getAll: (e, n) => o("lead/comment", e, n),
  /**
   * Update lead comment
   * Documentation is to be generated
   */
  update: (e, n) => l(
    `lead/comment/${e.commentObjectId}`,
    e,
    n
  )
}, Oa = {
  /**
   * Get a light version of a project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_validateandgetmarketplaceprojectlight
   */
  validateAndGetMarketplaceProjectLight: (e, n, a) => o(
    `marketplace/${e}/getLightProject/vault/${n}/project/${a}`
  ),
  /**
   * Get the first x resources from a projectx
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getmarketplaceprojectresources
   */
  getMarketplaceProjectResources: (e, n, a, t) => o(
    `marketplace/${e}/getProjectResources/vault/${n}/project/${a}`,
    { amountOfImages: t }
  ),
  /**
   * Get the project and units
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getmarketplaceprojectandunits
   */
  getMarketplaceProjectAndUnits: (e, n, a) => o(
    `marketplace/${e}/getProject/vault/${n}/project/${a}`
  ),
  /**
   * Search marketplace projects
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_searchmarketplaceproject
   */
  searchProjects: (e, n) => o(`marketplace/${e}/search/projects`, n),
  /**
   * Get project amount of units
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getprojectunitscount
   */
  getUnitCount: (e) => m("marketplace/projects/unitCount", e),
  /**
   * Get filters biggest and smallest values
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getmarketplaceedgevalues
   */
  getMarketplaceFiltersEdges: (e) => o(`marketplace/${e}/search/projects/edges`),
  /**
   * Get recently published projects
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getlastpublishedmarketplaceprojects
   */
  getLastPublishedProjects: (e, n) => o(`/marketplace/${e}/projects/lastpublished`, {
    amountOfProjects: n
  }),
  /**
   * Get projects in particular state of units with particular percentage
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getprojectswithunitspercentageinstatelist
   */
  getProjectsWithUnitsPercentage: (e, n, a) => m(
    `/marketplace/${e}/projects/withUnitsPercentageInStateList`,
    { percentageInStates: n, unitStateList: a }
  ),
  /**
   * Get number of available projects per province
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getamountprojectsbyprovince
   */
  getProjectsPerProvince: (e, n) => m(
    `/marketplace/${e}/projects/amountByProvince`,
    n
  ),
  /**
   * Publish units to marketplace project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_publishunitbulktomarketplace
   */
  publishUnitBulkToMarketplace: (e, n, a) => m(
    `/marketplace/${e}/publishUnitBulkToMarketplace`,
    n,
    a
  ),
  /**
   * Register a new marketplace user
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_registermarketplaceuser
   */
  registerMarketplaceUser: (e) => m("/users/marketplace/registerMarketplaceUser", e),
  /**
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_registermarketplaceuseroauth
   */
  registerMarketplaceUserOAuth: (e) => m("/users/marketplace/registerMarketplaceUserOauth", e),
  /**
   * Create a search preference of an authenticated user
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_create_27
   */
  createMarketplaceUserSearchPreference: (e, n) => m(
    "marketplaceUserSearchPreference",
    e,
    n
  ),
  /**
   * Update a search preference of an authenticated user
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_update_24
   */
  updateMarketplaceUserSearchPreference: (e, n, a) => l(
    `marketplaceUserSearchPreference/${e}`,
    n,
    a
  ),
  /**
   * Delete a search preference of an authenticated user
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_delete_10
   */
  deleteMarketplaceUserSearchPreference: (e, n) => E(
    `marketplaceUserSearchPreference/${e}`,
    n
  ),
  /**
   * Get all the search preferences of the authenticated user
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getall_18
   */
  getAllMarketplaceUserSearchPreferences: (e, n) => o(
    "marketplaceUserSearchPreference",
    e,
    n
  ),
  /**
   * Get all the marketplace favourites for a user
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getall_18
   */
  getAllMarketplaceProjectFavourites: (e, n, a) => o(
    "marketplaceUserProjectFavourite",
    {
      vaultId: e,
      marketplaceId: n,
      loadProjects: !0
    },
    a
  ),
  /**
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_create_27
   */
  createFavouriteProject: (e, n) => m(
    "marketplaceUserProjectFavourite",
    e,
    n
  ),
  /**
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_delete_10
   */
  deleteFavouriteProject: (e, n) => E(
    `marketplaceUserProjectFavourite/${e}`,
    n
  ),
  /**
   https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_create_29
   */
  createFavouriteUnit: (e, n) => m(
    "marketplaceUserUnitFavourite",
    e,
    n
  ),
  /**
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_delete_12
   */
  deleteFavouriteUnit: (e, n) => E(
    `marketplaceUserUnitFavourite/${e}`,
    n
  ),
  /**
   https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_getall_20
   */
  getAllMarketplaceUnitFavourites: (e, n, a) => o(
    "marketplaceUserUnitFavourite",
    {
      vaultId: e,
      marketplaceId: n,
      loadUnits: !0
    },
    a
  )
};
var sa = /* @__PURE__ */ ((e) => (e[e.map = 0] = "map", e[e.gallery = 1] = "gallery", e[e.cards = 2] = "cards", e))(sa || {}), ua = /* @__PURE__ */ ((e) => (e[e.year = 0] = "year", e[e.week = 1] = "week", e))(ua || {});
const Ba = {
  /**
   * Get markeplace settings
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_33
   * @param {string} sessionToken
   * @param {string} vaultId
   */
  get: (e, n) => o(
    `marketplacesettings/getByVaultId/${n}`,
    {},
    e
  ),
  /**
   * Create markeplace settings
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_30
   * @param {string} sessionToken
   * @param {Object} params
   */
  create: (e, n) => m("marketplacesettings", n, e),
  /**
   * Update markeplace settings
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_28
   * @param {string} sessionToken
   * @param {string} vaultId
   * @param {Object} params
   */
  update: (e, n, a) => l(`marketplacesettings/${n}`, a, e)
}, $a = {
  /**
   * Create a navigation collection
   *https://devapiv2.vr-tual.media/asciidoc/index.html#_create_23
   */
  create: (e, n) => m("navigationcollections", e, n),
  /**
   * update a navigation collection
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_21
   */
  update: (e, n, a) => l(
    `navigationcollections/${e}`,
    n,
    a
  ),
  /**
   * Get a navigation collection
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get
   */
  get: (e, n, a) => o(
    `navigationcollections/${e}`,
    { loadNestedTree: n },
    a
  )
};
var la = /* @__PURE__ */ ((e) => (e.explore = "explore", e.navigationSpot = "navigationSpot", e.infoSpot = "infoSpot", e.mediaSpot = "mediaSpot", e.polygonSpot = "polygonSpot", e.mediaView = "mediaView", e.mapView = "mapView", e.unitSpot = "unitSpot", e.line = "line", e.uspSpot = "uspSpot", e.turntableSpot = "turntableSpot", e.album360Spot = "album360Spot", e))(la || {}), pa = /* @__PURE__ */ ((e) => (e[e.TOP_LEFT = 0] = "TOP_LEFT", e[e.TOP_RIGHT = 1] = "TOP_RIGHT", e[e.BOTTOM_LEFT = 2] = "BOTTOM_LEFT", e[e.BOTTOM_RIGHT = 3] = "BOTTOM_RIGHT", e))(pa || {}), ga = /* @__PURE__ */ ((e) => (e[e.small = 0] = "small", e[e.medium = 1] = "medium", e[e.large = 2] = "large", e[e.original = 3] = "original", e))(ga || {});
const Na = {
  /**
   * Create a navigation item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_20
   * @param {Object} createParams
   * @param {string} sessionToken
   */
  create: (e, n) => m("navigationitems", e, n),
  /**
   * Update a navigation item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_22
   */
  update: (e, n, a) => l(
    `navigationitems/${e}`,
    n,
    a
  ),
  /**
   * Get the navigationitem
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_23
   * @param {string} navigationItemObjectId
   */
  get: (e, n) => o(
    `navigationitems/${e}`,
    {},
    n
  ),
  /**
   * Get by showcase code
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbyshowcasecode
   */
  getByShowcaseCode: (e) => o(`navigationitems/getByShowcaseCode/${e}`, {}),
  /**
   * Get by share item code
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbycode_3
   */
  getByShareItemCode: (e) => o(`navigationitems/getByCode/${e}`, {})
}, Da = {
  /**
   * Create a navigationTargetValue, a navigationTargetValue holds the destination of the navigation target since it's shared between other navigation targets.
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_32
   * @param {Object} createParams
   * @param {string} sessionToken
   */
  create: (e, n) => m("navigationtargetvalue", e, n),
  /**
   * Update a navigationTargetValue
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_31
   * @param {string} objectId
   * @param {Object} updateParams
   * @param {string} sessionToken
   */
  update: (e, n, a) => l(
    `navigationtargetvalue/${e}`,
    n,
    a
  )
}, Ta = {
  /**
   * Get all Prompto notifications
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getallpromptonotifications
   * @param {String} sessionToken
   */
  getAll: (e, n) => {
    const { userId: a, ...t } = n;
    let r = `?userId=${a}`;
    return Object.entries(t).forEach(([c, g]) => {
      String(g) && (r += `&${c}=${g}`);
    }), o(
      `notification/promptoNotification/getAll${r}`,
      {},
      e
    );
  },
  /**
   * Get last time opened prompto notifications timestamp
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_lasttimeopenednotificationsget
   * @param {String} userObjectId
   * @param {String} sessionToken
   */
  getLastTimeOpenedTimestamp: (e, n) => o(
    `users/${e}/userNotificationsSettings`,
    {},
    n
  ),
  /**
   * Update last time opened prompto notifications timestamp
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_lasttimeopenednotificationsupdate
   * @param {String} userObjectId
   * @param {String} sessionToken
   */
  updateLastTimeOpenedTimestamp: (e, n) => l(
    `users/${e}/userNotificationsSettings`,
    {
      updateLastTimeOpenedNotifications: !0
    },
    n
  ),
  updateLastTimeOpenedUpdatesTimestamp: (e, n) => l(
    `users/${e}/userNotificationsSettings`,
    {
      updateLastTimeOpenedUpdates: !0
    },
    n
  ),
  markAsRead: (e, n) => l(
    "notification/promptoNotification/markAsReadBulk",
    e,
    n
  )
}, La = {
  /**
   * Get Portal settings
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_36
   * @param {string} sessionToken
   * @param {string} vaultId
   */
  create: (e, n) => m("portalsettings", e, n),
  /**
   * Update Portal settings
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_34
   * @param {string} sessionToken
   * @param {string} Portal settings id
   * @param {Object} params
   */
  update: (e, n, a) => l(`portalsettings/${n}`, a, e),
  /**
   * Get Portal settings
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbyvaultid_1
   * @param {string} sessionToken
   * @param {string} vaultId
   */
  get: (e, n) => o(
    `portalsettings/getByVaultId/${e}`,
    {},
    n
  )
}, ja = {
  /**
   * Get a productboard SSO token
   * Productboard docs: https://support.productboard.com/hc/en-us/articles/360058173373#h_01EX0E896GDHGPCNJE7CX6RYMR
   */
  getSSOToken: (e) => o("productboard", {}, e)
};
var da = /* @__PURE__ */ ((e) => (e[e.PROJECT = 0] = "PROJECT", e[e.BUILDING = 1] = "BUILDING", e[e.FLOOR = 2] = "FLOOR", e[e.PHASE = 3] = "PHASE", e[e.OTHER = 4] = "OTHER", e))(da || {}), ma = /* @__PURE__ */ ((e) => (e[e.IN_PROGRESS = 0] = "IN_PROGRESS", e[e.COMPLETED = 1] = "COMPLETED", e[e.ARCHIVED = 2] = "ARCHIVED", e[e.DRAFT = 3] = "DRAFT", e[e.UPCOMING = 4] = "UPCOMING", e))(ma || {});
const Ga = {
  /**
   * Create a project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_38
   */
  create: (e, n, a) => m(
    `vaults/${e}/projectSections`,
    n,
    a
  ),
  /**
   * update a project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_36
   */
  update: (e, n, a, t) => l(
    `vaults/${n}/projectSections/${e}`,
    a,
    t
  ),
  /**
   * Delete a project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_delete_11
   */
  delete: (e, n, a) => E(
    `vaults/${n}/projectSections/${e}`,
    a
  ),
  /**
   * Delete a project address
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_deleteaddress
   */
  deleteAddress: (e, n, a) => E(
    `vaults/${n}/projectSections/${e}/deleteAddress`,
    a
  ),
  /**
   * Get the project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_39
   */
  get: (e, n, a, t = {}) => o(
    `vaults/${n}/projectSections/${e}`,
    t,
    a
  ),
  /**
   * Get the project info
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getinfo
   */
  getInfo: (e, n, a) => o(
    `vaults/${n}/projectSections/${e}/info`,
    {},
    a
  ),
  /**
   * DEPRECATED
   * Get the project dashboard url
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getdashboardurl
   */
  getDashboardUrl: (e, n, a) => o(
    `vaults/${n}/projectSections/${e}/dashboard`,
    {},
    a
  ),
  /**
   * Get unit slots usage report for a project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getunitslotusagereport
   */
  getUnitSlotUsageReport: (e, n, a) => o(
    `vaults/${e}/projectSections/${n}/getUnitSlotUsageReport`,
    {},
    a
  ),
  getAll: (e, n, a) => o(
    `vaults/${e}/projectSections`,
    n,
    a
  ),
  /**
   *  Returns list of user permission roles this user (based on sessionToken) is allowed to change/change to for this project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getallowedpermissionroles
   */
  getPermissionRoles: (e, n, a) => o(
    `vaults/${n}/projectSections/${e}/permissionroles`,
    {},
    a
  ),
  /**
   * Update the users on project level
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_inviteuserbulk
   */
  updateProjectUsers: (e, n, a, t) => m(
    `vaults/${n}/projectSections/${e}/users/bulk`,
    a,
    t
  ),
  /**
   * Get the list of unit types
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getall_21
   */
  getAllUnitTypes: (e, n, a) => {
    let t = `?vaultObjectId=${n}`;
    return a && (t += `&projectObjectId=${a}`), o(`unittype${t}`, {}, e);
  },
  /**
   * List all signature templates for project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_listsignaturetemplates
   */
  listSignatureTemplates: (e, n, a) => o(
    `vaults/${e}/projectSections/${n}/signature/templates`,
    {},
    a
  ),
  /**
   * Create signature request
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_createsignaturerequestembedded
   */
  createSignatureRequest: (e, n, a, t) => m(
    `vaults/${e}/projectSections/${n}/signature/requests`,
    a,
    t
  ),
  /**
   * Get project by showcase code
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getprojectbyshowcasecode
   */
  getProjectByCode: (e) => e ? o(`showcase/getProjectByShowcaseCode/${e}`, {}) : Promise.reject(new Error("no code given")),
  /**
   * Get project settings
   * todo: add link to documentation
   */
  getProjectSettings: (e, n, a) => o(
    "projectSettings",
    {
      vaultId: e,
      projectId: n
    },
    a
  ),
  /**
   * Create project settings
   * todo: add link to documentation
   */
  createProjectSettings: (e, n, a) => m(
    "projectSettings",
    {
      vaultId: e,
      projectId: n,
      destinationEmailForLeadCreation: ""
    },
    a
  ),
  /**
   * Update project settings
   * todo: add link to documentation
   */
  updateProjectSettings: (e, n, a) => l(`projectSettings/${e}`, n, a)
};
var ha = /* @__PURE__ */ ((e) => (e[e.FOLDER = 0] = "FOLDER", e[e.CONTENT_ITEM = 1] = "CONTENT_ITEM", e))(ha || {});
const xa = {
  /**
   * Create project folder configuration
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_37
   * @param {Object} body
   * @param {string} sessionToken
   */
  create: (e, n) => m("projectfolderconfiguration", e, n),
  /**
   * Put project folder configuration
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_35
   * @param {string} projectId
   * @param {Object} params
   * @param {string} sessionToken
   */
  update: (e, n, a) => l(
    `projectfolderconfiguration/${e}`,
    n,
    a
  ),
  /**
   * Get project folder configuration
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbyprojectsectionobjectid
   * @param {string} projectId
   * @param {string} sessionToken
   */
  get: (e, n) => o(
    `projectfolderconfiguration/getByProjectSectionObjectId/${e}`,
    {},
    n
  )
}, Va = {
  /**
   * set end session timestamp
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updateendsessiontimestamp
   * @param {{sessionId: string}} body
   */
  updateEndTimestamp: (e) => l("promptosession/updateEndSessionTimestamp", e, "")
}, Ha = {
  /**
   * Get sales person contact
   * The documentation is not ready yet
   * @param {string} objectId
   * @param {string} sessionToken
   */
  getSalesPersonContact: (e, n) => o(
    `stripe/salesPersonContact/${e}`,
    n
  ),
  /**
   * Update sales person contact
   * The documentation is not ready yet
   * @param {string} objectId
   * @param {string} sessionToken
   */
  updateSalesPersonContact: (e, n, a) => o(
    `stripe/salesPersonContact/${e}`,
    n,
    a
  )
};
var fa = /* @__PURE__ */ ((e) => (e.contentItem = "contentItem", e.contentCollection = "contentCollection", e.navigationItem = "navigationItem", e.showcaseItem = "showcaseItem", e))(fa || {});
const L = {
  /**
   * Create share item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_2
   * @param {Object} params
   * @param {string} sessionToken
   */
  create: (e, n) => m("shareitems", e, n),
  /**
   * Get single shareitem by its id
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_2
   * @param {string} shareItemId
   */
  get: (e) => o(`shareitems/${e}?loadCustomerInfo=true`),
  /**
   * Get all ShareItems
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getall
   */
  getAll: (e, n) => o("shareitems", e, n),
  /** Get share item by code
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbycode_1
   */
  getByCode: (e, n) => o(`shareitems/getByCode/${e}`, {}, n),
  /**
   * Update a share item
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_2
   */
  update: (e, n, a) => l(`shareitems/${e}`, n, a),
  mockGetAll: (e, n = {}) => (L.getAll.mockReset(), L.getAll.mockImplementation(() => {
    if (n?.error) {
      const a = n.error;
      return Promise.reject({
        status: a.httpStatus,
        data: { code: a.errorCode, message: a.errorMessage }
      });
    } else
      return Promise.resolve({ data: { shareItemList: e } });
  })),
  mockUpdate: (e = !0) => (L.update.mockReset(), L.update.mockImplementation(
    (n, a, t) => e ? Promise.resolve({
      status: 200,
      data: {
        shareItem: { objectId: n, ...a }
      }
    }) : Promise.reject({ status: 404 })
  ))
}, qa = {
  /**
   * Create Showcase configuration
   * @param {Object} params
   * @param {string} sessionToken
   */
  create: (e, n) => m("showcaseconfiguration", e, n),
  /**
   * update Showcase configuration
   * @param {Object} params
   * @param {string} sessionToken
   */
  update: (e, n, a) => l(`showcaseconfiguration/${e}`, n, a),
  /**
   * Get a showcase configuration
   * @param {string} objectId
   */
  get: (e) => o(`showcaseconfiguration/${e}`)
};
var va = /* @__PURE__ */ ((e) => (e[e.unitTitleUpdate = 0] = "unitTitleUpdate", e[e.unitPriceUpdate = 1] = "unitPriceUpdate", e[e.unitNumberOfBedroomsUpdate = 2] = "unitNumberOfBedroomsUpdate", e[e.unitNumberOfBathroomsUpdate = 3] = "unitNumberOfBathroomsUpdate", e[e.unitDescriptionUpdate = 4] = "unitDescriptionUpdate", e[e.unitSurfaceUpdate = 5] = "unitSurfaceUpdate", e[e.unitReferenceUpdate = 6] = "unitReferenceUpdate", e[e.unitExternalLinkUpdate = 7] = "unitExternalLinkUpdate", e[e.unitCRMIdUpdate = 8] = "unitCRMIdUpdate", e))(va || {});
const Ka = {
  /**
   * User simple actions tracking
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackuseractivity
   * @param {string} sessionToken
   * @param {{sessionId: string, action: string}} params
   */
  trackUserSimpleAction: (e, n) => l("track/trackUserActivity", e, n),
  /**
   * track when user uploads files
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackcontentupload
   * @param {object} params
   * @param {string} body.sessionId
   * @param {string} body.projectObjectId
   * @param {string} body.vaultObjectId
   * @param {string=} body.unitObjectId
   * @param {string[]} body.contentItemIdList
   * @param {string} sessionToken
   */
  trackContentUpload: (e, n) => l("track/trackContentUpload", e, n),
  /**
   * track when user downloads files
   * TODO: add link to documentation
   * @param {object} params
   * @param {string} body.action
   * @param {string} body.sessionId
   * @param {string} body.vaultId
   * @param {string} body.projectId
   * @param {string[]=} body.contentItemIdList
   * @param {string=} body.zipFileSize
   * @param {string} sessionToken
   */
  trackContentDownload: (e, n) => l(
    "track/trackPortalMediaDownloading",
    e,
    n
  ).catch(() => console.warn(`Failed to track ${e.action}`)),
  /**
   * Track unit state update
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackunitstateupdate
   * @param {string} sessionToken
   * @param {Object} params
   */
  trackUnitStateUpdate: (e, n) => l("track/trackUnitStateUpdate", n, e),
  /**
   * Track unit floorplan update
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackunitfloorplanupdate
   * @param {string} sessionToken
   * @param {Object} params
   */
  trackUnitFloorplanUpdate: (e, n) => l("track/trackUnitFloorplanUpdate", n, e),
  /**
   * Track unit info update
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackunitinfoupdate
   * @param {string} sessionToken
   * @param {Object} params
   */
  trackUnitInfoUpdate: (e, n) => l("track/trackUnitInfoUpdate", n, e),
  /**
   * Track when user opens a project
   * TODO: add link to documentation
   * @param {{ sessionId: string, projectObjectId: string, vaultObjectId: string }} params
   */
  trackUserProjectOpen: (e, n) => l("track/trackProjectOpen", e, n),
  /**
   * Track when user opens a project in Marketplace
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackmarketplaceprojectvisit
   */
  trackMarketplaceProjectVisit: (e) => l("track/trackMarketplaceProjectVisit", e),
  /**
   * Track when user opens a unit page in Marketplace
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackmarketplaceunitvisitor
   */
  trackMarketplaceUnitVisitor: (e) => l("track/trackMarketplaceUnitVisitor", e),
  /**
   * Track when user interacts with the Get In Touch form
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackgetintouchinteraction
   */
  trackGetInTouchInteraction: (e) => l("track/trackGetInTouchInteraction", e),
  /**
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_updateactionupdatedatfield
   */
  updateActionUpdateAtField: (e) => l("track/updateActionUpdatedAtField", e),
  /**
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackvisitorunitactivity
   */
  trackShowcaseUnitVisitor: (e) => l("track/trackVisitorUnitActivity", e),
  /**
   * Track Showcase loaded action
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackfinishloadingshowcase
   */
  trackShowcaseLoaded: (e) => l("track/trackFinishLoadingShowcase", e),
  /**
   * Track when shared collection page was opened
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_tracksharedcontentcollectionviewed
   */
  trackSharedCollectionPageOpened: (e) => l("track/trackSharedContentCollectionViewed", e),
  /**
   * Track content item from shared collection was opened
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackcontentitemviewed
   */
  trackSingleItemFromSharedCollectionViewed: (e) => l("track/trackContentItemViewed", e),
  /**
   * Track content item from shared collection was opened
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_trackcontentitemdownloaded
   */
  trackSharedCollectionContentDownload: (e) => l("track/trackContentItemDownloaded", e),
  /**
   * Track marketplace action
   *
   */
  trackMarketplaceAction: (e) => l("track/trackMarketplaceAction", e)
}, Ya = {
  /**
   * Create a navigationTarget, this will hold on which view it should be displayed and which target it has.
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_33
   * @param {Object} createParams
   * @param {string} sessionToken
   */
  create: (e, n) => m("turnnavigationtarget", e, n),
  /**
   * Update navigationTarget
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_38
   * @param {string} navigationTargetObjectId
   * @param {Object} updateParams
   * @param {string} sessionToken
   */
  update: (e, n, a) => l(
    `turnnavigationtarget/${e}`,
    n,
    a
  )
}, Ja = {
  /**
   * Create turnPointOfView, a turnPointOfView is used to group the navigation targets per view/angle
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_34
   * @param {Object} createParams
   * @param {string} sessionToken
   */
  create: (e, n) => m("turnpointofview", e, n),
  /**
   * Update turnPointOfView, a turnPointOfView is used to group the navigation targets per view/angle
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_33
   * @param {string} turnPointOfViewObjectId
   * @param {Object} updateParams
   * @param {string} sessionToken
   */
  update: (e, n, a) => l(
    `turnpointofview/${e}`,
    n,
    a
  )
}, za = {
  /**
   * Create the actual turntable object
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_35
   * @param {Object} createParams
   * @param {string} sessionToken
   */
  create: (e, n) => m("turntable", e, n),
  /**
   * Update given turntable
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_34
   * @param {string} objectId
   * @param {Object} params
   * @param {string} sessionToken
   * @returns
   */
  update: (e, n, a) => l(`turntable/${e}`, n, a),
  /**
   * Get the turntable for the showcase
   * @param {string} turntableObjectId
   */
  get: (e, n) => o(`turntable/${e}`, n),
  /**
   * Get all turntables
   * ... docs werent generated yet ...
   * @param {Object} params
   * @param {string} sessionToken
   */
  getAll: (e, n) => o("turntable", e, n)
};
var ya = /* @__PURE__ */ ((e) => (e.AVAILABLE = "AVAILABLE", e.IN_OPTION = "IN_OPTION", e.SOLD = "SOLD", e.ARCHIVED = "ARCHIVED", e.DRAFT = "DRAFT", e))(ya || {}), Sa = /* @__PURE__ */ ((e) => (e[e.rental = 0] = "rental", e[e.sale = 1] = "sale", e))(Sa || {}), Aa = /* @__PURE__ */ ((e) => (e.price = "price", e.surface = "surface", e.numberOfBedrooms = "numberOfBedrooms", e.numberOfBathrooms = "numberOfBathrooms", e))(Aa || {});
const Xa = {
  /**
   * Create a unit
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_40
   */
  create: (e, n, a) => m(`vaults/${e}/units`, n, a),
  /**
   * Create a unit bulk
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_createbulk_3
   */
  createBulk: (e, n, a) => m(`vaults/${e}/units/bulk`, n, a),
  /**
   * Get single unit
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_37
   */
  get: (e, n, a) => o(`vaults/${n}/units/${a}`, {}, e),
  /**
   * update a unit
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_38
   */
  update: (e, n, a, t) => l(
    `vaults/${n}/units/${e}`,
    a,
    t
  ),
  /**
   * update multiple units
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updatebulk_5
   */
  updateBulk: (e, n, a) => l(
    `vaults/${e}/units/bulk`,
    n,
    a
  ),
  /**
   * Delete a unit
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_delete_12
   */
  delete: (e, n, a) => E(
    `vaults/${n}/units/${e}`,
    a
  ),
  /**
   * Get all the units for a project
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getall_23
   */
  getAll: (e, n, a) => o(`vaults/${e}/units`, n, a)
}, Za = {
  // User login
  login: (e, n) => {
    let a = "users/login";
    if (e) {
      a = a += "?";
      const t = Object.entries(e).reduce((r, [c, g]) => (r.push(`${c}=${g}`), r), []);
      a += t.join("&");
    }
    return m(a, n);
  },
  // User login OAuth
  loginOAuth: (e) => m("users/loginoauth", e),
  /**
   * Logout user, Will invalidate the token.
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_logout
   */
  logout: (e) => m("users/logout", {}, e),
  /**
   * User renew session token
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_renewsession
   * @param {object} queryParams
   * @param {object} body
   * @param {string} sessionToken
   */
  renewSessionToken: (e, n, a) => {
    let t = "users/renewsession";
    if (e) {
      t = t += "?";
      const r = Object.entries(e).reduce((c, [g, S]) => (c.push(`${g}=${S}`), c), []);
      t += r.join("&");
    }
    return m(t, n, a);
  },
  /**
   * Get user info
   * @param {string} sessionToken
   */
  getInfo: (e, n = {}) => o("/users/info", n, e),
  /**
   * Update user
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_47
   */
  update: (e, n, a) => l(`users/${e}`, n, a),
  /**
   * Update user password
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updatepassword
   */
  updatePassword: (e, n, a) => l(`users/${n}/password`, e, a),
  /**
   * Request to reset a password
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_requestresetpassword
   */
  requestResetPassword: (e) => m("users/requestresetpassword", e),
  /**
   * reset a password
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_resetpassword
   */
  resetPassword: (e) => m("users/resetpassword", e),
  /**
   *  Check the invitation of a user
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_checkinvitation
   */
  checkInvitation: (e) => m("users/checkinvitation", e),
  /**
   *  Check if a user exists
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_check
   */
  checkIfExists: (e) => m("users/check", e),
  /**
   * create a new user
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_50
   */
  create: (e) => m("users", e),
  /**
   * Accept an invitation
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_acceptinvitation
   */
  acceptInvitation: (e, n, a) => m(
    `users/${e}/invitation/${n}`,
    {},
    a
  ),
  /**
   * Set the default vault of a user
   * It sets the uer default vault. The first vault in the user vault list is the default vault. This endpoint changes the first vault in the user vault list
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_setdefaultvault
   */
  setDefaultVault: (e, n, a) => l(
    `users/${e}/setdefaultvault`,
    n,
    a
  )
}, Ua = {
  countries: {
    AD: {
      name: "Andorra",
      native: "Andorra",
      phone: "376",
      continent: "EU",
      capital: "Andorra la Vella",
      currency: "EUR",
      languages: "ca"
    },
    AE: {
      name: "United Arab Emirates",
      native: "دولة الإمارات العربية المتحدة",
      phone: "971",
      continent: "AS",
      capital: "Abu Dhabi",
      currency: "AED",
      languages: "ar"
    },
    AF: {
      name: "Afghanistan",
      native: "افغانستان",
      phone: "93",
      continent: "AS",
      capital: "Kabul",
      currency: "AFN",
      languages: "ps,uz,tk"
    },
    AG: {
      name: "Antigua and Barbuda",
      native: "Antigua and Barbuda",
      phone: "1268",
      continent: "NA",
      capital: "Saint John's",
      currency: "XCD",
      languages: "en"
    },
    AI: {
      name: "Anguilla",
      native: "Anguilla",
      phone: "1264",
      continent: "NA",
      capital: "The Valley",
      currency: "XCD",
      languages: "en"
    },
    AL: {
      name: "Albania",
      native: "Shqipëria",
      phone: "355",
      continent: "EU",
      capital: "Tirana",
      currency: "ALL",
      languages: "sq"
    },
    AM: {
      name: "Armenia",
      native: "Հայաստան",
      phone: "374",
      continent: "AS",
      capital: "Yerevan",
      currency: "AMD",
      languages: "hy,ru"
    },
    AO: {
      name: "Angola",
      native: "Angola",
      phone: "244",
      continent: "AF",
      capital: "Luanda",
      currency: "AOA",
      languages: "pt"
    },
    AQ: {
      name: "Antarctica",
      native: "Antarctica",
      phone: "",
      continent: "AN",
      capital: "",
      currency: "",
      languages: ""
    },
    AR: {
      name: "Argentina",
      native: "Argentina",
      phone: "54",
      continent: "SA",
      capital: "Buenos Aires",
      currency: "ARS",
      languages: "es,gn"
    },
    AS: {
      name: "American Samoa",
      native: "American Samoa",
      phone: "1684",
      continent: "OC",
      capital: "Pago Pago",
      currency: "USD",
      languages: "en,sm"
    },
    AT: {
      name: "Austria",
      native: "Österreich",
      phone: "43",
      continent: "EU",
      capital: "Vienna",
      currency: "EUR",
      languages: "de"
    },
    AU: {
      name: "Australia",
      native: "Australia",
      phone: "61",
      continent: "OC",
      capital: "Canberra",
      currency: "AUD",
      languages: "en"
    },
    AW: {
      name: "Aruba",
      native: "Aruba",
      phone: "297",
      continent: "NA",
      capital: "Oranjestad",
      currency: "AWG",
      languages: "nl,pa"
    },
    AX: {
      name: "Åland",
      native: "Åland",
      phone: "358",
      continent: "EU",
      capital: "Mariehamn",
      currency: "EUR",
      languages: "sv"
    },
    AZ: {
      name: "Azerbaijan",
      native: "Azərbaycan",
      phone: "994",
      continent: "AS",
      capital: "Baku",
      currency: "AZN",
      languages: "az,hy"
    },
    BA: {
      name: "Bosnia and Herzegovina",
      native: "Bosna i Hercegovina",
      phone: "387",
      continent: "EU",
      capital: "Sarajevo",
      currency: "BAM",
      languages: "bs,hr,sr"
    },
    BB: {
      name: "Barbados",
      native: "Barbados",
      phone: "1246",
      continent: "NA",
      capital: "Bridgetown",
      currency: "BBD",
      languages: "en"
    },
    BD: {
      name: "Bangladesh",
      native: "Bangladesh",
      phone: "880",
      continent: "AS",
      capital: "Dhaka",
      currency: "BDT",
      languages: "bn"
    },
    BE: {
      name: "Belgium",
      native: "België",
      phone: "32",
      continent: "EU",
      capital: "Brussels",
      currency: "EUR",
      languages: "nl,fr,de"
    },
    BF: {
      name: "Burkina Faso",
      native: "Burkina Faso",
      phone: "226",
      continent: "AF",
      capital: "Ouagadougou",
      currency: "XOF",
      languages: "fr,ff"
    },
    BG: {
      name: "Bulgaria",
      native: "България",
      phone: "359",
      continent: "EU",
      capital: "Sofia",
      currency: "BGN",
      languages: "bg"
    },
    BH: {
      name: "Bahrain",
      native: "‏البحرين",
      phone: "973",
      continent: "AS",
      capital: "Manama",
      currency: "BHD",
      languages: "ar"
    },
    BI: {
      name: "Burundi",
      native: "Burundi",
      phone: "257",
      continent: "AF",
      capital: "Bujumbura",
      currency: "BIF",
      languages: "fr,rn"
    },
    BJ: {
      name: "Benin",
      native: "Bénin",
      phone: "229",
      continent: "AF",
      capital: "Porto-Novo",
      currency: "XOF",
      languages: "fr"
    },
    BL: {
      name: "Saint Barthélemy",
      native: "Saint-Barthélemy",
      phone: "590",
      continent: "NA",
      capital: "Gustavia",
      currency: "EUR",
      languages: "fr"
    },
    BM: {
      name: "Bermuda",
      native: "Bermuda",
      phone: "1441",
      continent: "NA",
      capital: "Hamilton",
      currency: "BMD",
      languages: "en"
    },
    BN: {
      name: "Brunei",
      native: "Negara Brunei Darussalam",
      phone: "673",
      continent: "AS",
      capital: "Bandar Seri Begawan",
      currency: "BND",
      languages: "ms"
    },
    BO: {
      name: "Bolivia",
      native: "Bolivia",
      phone: "591",
      continent: "SA",
      capital: "Sucre",
      currency: "BOB,BOV",
      languages: "es,ay,qu"
    },
    BQ: {
      name: "Bonaire",
      native: "Bonaire",
      phone: "5997",
      continent: "NA",
      capital: "Kralendijk",
      currency: "USD",
      languages: "nl"
    },
    BR: {
      name: "Brazil",
      native: "Brasil",
      phone: "55",
      continent: "SA",
      capital: "Brasília",
      currency: "BRL",
      languages: "pt"
    },
    BS: {
      name: "Bahamas",
      native: "Bahamas",
      phone: "1242",
      continent: "NA",
      capital: "Nassau",
      currency: "BSD",
      languages: "en"
    },
    BT: {
      name: "Bhutan",
      native: "ʼbrug-yul",
      phone: "975",
      continent: "AS",
      capital: "Thimphu",
      currency: "BTN,INR",
      languages: "dz"
    },
    BV: {
      name: "Bouvet Island",
      native: "Bouvetøya",
      phone: "",
      continent: "AN",
      capital: "",
      currency: "NOK",
      languages: ""
    },
    BW: {
      name: "Botswana",
      native: "Botswana",
      phone: "267",
      continent: "AF",
      capital: "Gaborone",
      currency: "BWP",
      languages: "en,tn"
    },
    BY: {
      name: "Belarus",
      native: "Белару́сь",
      phone: "375",
      continent: "EU",
      capital: "Minsk",
      currency: "BYR",
      languages: "be,ru"
    },
    BZ: {
      name: "Belize",
      native: "Belize",
      phone: "501",
      continent: "NA",
      capital: "Belmopan",
      currency: "BZD",
      languages: "en,es"
    },
    CA: {
      name: "Canada",
      native: "Canada",
      phone: "1",
      continent: "NA",
      capital: "Ottawa",
      currency: "CAD",
      languages: "en,fr"
    },
    CC: {
      name: "Cocos [Keeling] Islands",
      native: "Cocos (Keeling) Islands",
      phone: "61",
      continent: "AS",
      capital: "West Island",
      currency: "AUD",
      languages: "en"
    },
    CD: {
      name: "Democratic Republic of the Congo",
      native: "République démocratique du Congo",
      phone: "243",
      continent: "AF",
      capital: "Kinshasa",
      currency: "CDF",
      languages: "fr,ln,kg,sw,lu"
    },
    CF: {
      name: "Central African Republic",
      native: "Ködörösêse tî Bêafrîka",
      phone: "236",
      continent: "AF",
      capital: "Bangui",
      currency: "XAF",
      languages: "fr,sg"
    },
    CG: {
      name: "Republic of the Congo",
      native: "République du Congo",
      phone: "242",
      continent: "AF",
      capital: "Brazzaville",
      currency: "XAF",
      languages: "fr,ln"
    },
    CH: {
      name: "Switzerland",
      native: "Schweiz",
      phone: "41",
      continent: "EU",
      capital: "Bern",
      currency: "CHE,CHF,CHW",
      languages: "de,fr,it"
    },
    CI: {
      name: "Ivory Coast",
      native: "Côte d'Ivoire",
      phone: "225",
      continent: "AF",
      capital: "Yamoussoukro",
      currency: "XOF",
      languages: "fr"
    },
    CK: {
      name: "Cook Islands",
      native: "Cook Islands",
      phone: "682",
      continent: "OC",
      capital: "Avarua",
      currency: "NZD",
      languages: "en"
    },
    CL: {
      name: "Chile",
      native: "Chile",
      phone: "56",
      continent: "SA",
      capital: "Santiago",
      currency: "CLF,CLP",
      languages: "es"
    },
    CM: {
      name: "Cameroon",
      native: "Cameroon",
      phone: "237",
      continent: "AF",
      capital: "Yaoundé",
      currency: "XAF",
      languages: "en,fr"
    },
    CN: {
      name: "China",
      native: "中国",
      phone: "86",
      continent: "AS",
      capital: "Beijing",
      currency: "CNY",
      languages: "zh"
    },
    CO: {
      name: "Colombia",
      native: "Colombia",
      phone: "57",
      continent: "SA",
      capital: "Bogotá",
      currency: "COP",
      languages: "es"
    },
    CR: {
      name: "Costa Rica",
      native: "Costa Rica",
      phone: "506",
      continent: "NA",
      capital: "San José",
      currency: "CRC",
      languages: "es"
    },
    CU: {
      name: "Cuba",
      native: "Cuba",
      phone: "53",
      continent: "NA",
      capital: "Havana",
      currency: "CUC,CUP",
      languages: "es"
    },
    CV: {
      name: "Cape Verde",
      native: "Cabo Verde",
      phone: "238",
      continent: "AF",
      capital: "Praia",
      currency: "CVE",
      languages: "pt"
    },
    CW: {
      name: "Curacao",
      native: "Curaçao",
      phone: "5999",
      continent: "NA",
      capital: "Willemstad",
      currency: "ANG",
      languages: "nl,pa,en"
    },
    CX: {
      name: "Christmas Island",
      native: "Christmas Island",
      phone: "61",
      continent: "AS",
      capital: "Flying Fish Cove",
      currency: "AUD",
      languages: "en"
    },
    CY: {
      name: "Cyprus",
      native: "Κύπρος",
      phone: "357",
      continent: "EU",
      capital: "Nicosia",
      currency: "EUR",
      languages: "el,tr,hy"
    },
    CZ: {
      name: "Czech Republic",
      native: "Česká republika",
      phone: "420",
      continent: "EU",
      capital: "Prague",
      currency: "CZK",
      languages: "cs,sk"
    },
    DE: {
      name: "Germany",
      native: "Deutschland",
      phone: "49",
      continent: "EU",
      capital: "Berlin",
      currency: "EUR",
      languages: "de"
    },
    DJ: {
      name: "Djibouti",
      native: "Djibouti",
      phone: "253",
      continent: "AF",
      capital: "Djibouti",
      currency: "DJF",
      languages: "fr,ar"
    },
    DK: {
      name: "Denmark",
      native: "Danmark",
      phone: "45",
      continent: "EU",
      capital: "Copenhagen",
      currency: "DKK",
      languages: "da"
    },
    DM: {
      name: "Dominica",
      native: "Dominica",
      phone: "1767",
      continent: "NA",
      capital: "Roseau",
      currency: "XCD",
      languages: "en"
    },
    DO: {
      name: "Dominican Republic",
      native: "República Dominicana",
      phone: "1809,1829,1849",
      continent: "NA",
      capital: "Santo Domingo",
      currency: "DOP",
      languages: "es"
    },
    DZ: {
      name: "Algeria",
      native: "الجزائر",
      phone: "213",
      continent: "AF",
      capital: "Algiers",
      currency: "DZD",
      languages: "ar"
    },
    EC: {
      name: "Ecuador",
      native: "Ecuador",
      phone: "593",
      continent: "SA",
      capital: "Quito",
      currency: "USD",
      languages: "es"
    },
    EE: {
      name: "Estonia",
      native: "Eesti",
      phone: "372",
      continent: "EU",
      capital: "Tallinn",
      currency: "EUR",
      languages: "et"
    },
    EG: {
      name: "Egypt",
      native: "مصر‎",
      phone: "20",
      continent: "AF",
      capital: "Cairo",
      currency: "EGP",
      languages: "ar"
    },
    EH: {
      name: "Western Sahara",
      native: "الصحراء الغربية",
      phone: "212",
      continent: "AF",
      capital: "El Aaiún",
      currency: "MAD,DZD,MRO",
      languages: "es"
    },
    ER: {
      name: "Eritrea",
      native: "ኤርትራ",
      phone: "291",
      continent: "AF",
      capital: "Asmara",
      currency: "ERN",
      languages: "ti,ar,en"
    },
    ES: {
      name: "Spain",
      native: "España",
      phone: "34",
      continent: "EU",
      capital: "Madrid",
      currency: "EUR",
      languages: "es,eu,ca,gl,oc"
    },
    ET: {
      name: "Ethiopia",
      native: "ኢትዮጵያ",
      phone: "251",
      continent: "AF",
      capital: "Addis Ababa",
      currency: "ETB",
      languages: "am"
    },
    FI: {
      name: "Finland",
      native: "Suomi",
      phone: "358",
      continent: "EU",
      capital: "Helsinki",
      currency: "EUR",
      languages: "fi,sv"
    },
    FJ: {
      name: "Fiji",
      native: "Fiji",
      phone: "679",
      continent: "OC",
      capital: "Suva",
      currency: "FJD",
      languages: "en,fj,hi,ur"
    },
    FK: {
      name: "Falkland Islands",
      native: "Falkland Islands",
      phone: "500",
      continent: "SA",
      capital: "Stanley",
      currency: "FKP",
      languages: "en"
    },
    FM: {
      name: "Micronesia",
      native: "Micronesia",
      phone: "691",
      continent: "OC",
      capital: "Palikir",
      currency: "USD",
      languages: "en"
    },
    FO: {
      name: "Faroe Islands",
      native: "Føroyar",
      phone: "298",
      continent: "EU",
      capital: "Tórshavn",
      currency: "DKK",
      languages: "fo"
    },
    FR: {
      name: "France",
      native: "France",
      phone: "33",
      continent: "EU",
      capital: "Paris",
      currency: "EUR",
      languages: "fr"
    },
    GA: {
      name: "Gabon",
      native: "Gabon",
      phone: "241",
      continent: "AF",
      capital: "Libreville",
      currency: "XAF",
      languages: "fr"
    },
    GB: {
      name: "United Kingdom",
      native: "United Kingdom",
      phone: "44",
      continent: "EU",
      capital: "London",
      currency: "GBP",
      languages: "en"
    },
    GD: {
      name: "Grenada",
      native: "Grenada",
      phone: "1473",
      continent: "NA",
      capital: "St. George's",
      currency: "XCD",
      languages: "en"
    },
    GE: {
      name: "Georgia",
      native: "საქართველო",
      phone: "995",
      continent: "AS",
      capital: "Tbilisi",
      currency: "GEL",
      languages: "ka"
    },
    GF: {
      name: "French Guiana",
      native: "Guyane française",
      phone: "594",
      continent: "SA",
      capital: "Cayenne",
      currency: "EUR",
      languages: "fr"
    },
    GG: {
      name: "Guernsey",
      native: "Guernsey",
      phone: "44",
      continent: "EU",
      capital: "St. Peter Port",
      currency: "GBP",
      languages: "en,fr"
    },
    GH: {
      name: "Ghana",
      native: "Ghana",
      phone: "233",
      continent: "AF",
      capital: "Accra",
      currency: "GHS",
      languages: "en"
    },
    GI: {
      name: "Gibraltar",
      native: "Gibraltar",
      phone: "350",
      continent: "EU",
      capital: "Gibraltar",
      currency: "GIP",
      languages: "en"
    },
    GL: {
      name: "Greenland",
      native: "Kalaallit Nunaat",
      phone: "299",
      continent: "NA",
      capital: "Nuuk",
      currency: "DKK",
      languages: "kl"
    },
    GM: {
      name: "Gambia",
      native: "Gambia",
      phone: "220",
      continent: "AF",
      capital: "Banjul",
      currency: "GMD",
      languages: "en"
    },
    GN: {
      name: "Guinea",
      native: "Guinée",
      phone: "224",
      continent: "AF",
      capital: "Conakry",
      currency: "GNF",
      languages: "fr,ff"
    },
    GP: {
      name: "Guadeloupe",
      native: "Guadeloupe",
      phone: "590",
      continent: "NA",
      capital: "Basse-Terre",
      currency: "EUR",
      languages: "fr"
    },
    GQ: {
      name: "Equatorial Guinea",
      native: "Guinea Ecuatorial",
      phone: "240",
      continent: "AF",
      capital: "Malabo",
      currency: "XAF",
      languages: "es,fr"
    },
    GR: {
      name: "Greece",
      native: "Ελλάδα",
      phone: "30",
      continent: "EU",
      capital: "Athens",
      currency: "EUR",
      languages: "el"
    },
    GS: {
      name: "South Georgia and the South Sandwich Islands",
      native: "South Georgia",
      phone: "500",
      continent: "AN",
      capital: "King Edward Point",
      currency: "GBP",
      languages: "en"
    },
    GT: {
      name: "Guatemala",
      native: "Guatemala",
      phone: "502",
      continent: "NA",
      capital: "Guatemala City",
      currency: "GTQ",
      languages: "es"
    },
    GU: {
      name: "Guam",
      native: "Guam",
      phone: "1671",
      continent: "OC",
      capital: "Hagåtña",
      currency: "USD",
      languages: "en,ch,es"
    },
    GW: {
      name: "Guinea-Bissau",
      native: "Guiné-Bissau",
      phone: "245",
      continent: "AF",
      capital: "Bissau",
      currency: "XOF",
      languages: "pt"
    },
    GY: {
      name: "Guyana",
      native: "Guyana",
      phone: "592",
      continent: "SA",
      capital: "Georgetown",
      currency: "GYD",
      languages: "en"
    },
    HK: {
      name: "Hong Kong",
      native: "香港",
      phone: "852",
      continent: "AS",
      capital: "City of Victoria",
      currency: "HKD",
      languages: "zh,en"
    },
    HM: {
      name: "Heard Island and McDonald Islands",
      native: "Heard Island and McDonald Islands",
      phone: "",
      continent: "AN",
      capital: "",
      currency: "AUD",
      languages: "en"
    },
    HN: {
      name: "Honduras",
      native: "Honduras",
      phone: "504",
      continent: "NA",
      capital: "Tegucigalpa",
      currency: "HNL",
      languages: "es"
    },
    HR: {
      name: "Croatia",
      native: "Hrvatska",
      phone: "385",
      continent: "EU",
      capital: "Zagreb",
      currency: "HRK",
      languages: "hr"
    },
    HT: {
      name: "Haiti",
      native: "Haïti",
      phone: "509",
      continent: "NA",
      capital: "Port-au-Prince",
      currency: "HTG,USD",
      languages: "fr,ht"
    },
    HU: {
      name: "Hungary",
      native: "Magyarország",
      phone: "36",
      continent: "EU",
      capital: "Budapest",
      currency: "HUF",
      languages: "hu"
    },
    ID: {
      name: "Indonesia",
      native: "Indonesia",
      phone: "62",
      continent: "AS",
      capital: "Jakarta",
      currency: "IDR",
      languages: "id"
    },
    IE: {
      name: "Ireland",
      native: "Éire",
      phone: "353",
      continent: "EU",
      capital: "Dublin",
      currency: "EUR",
      languages: "ga,en"
    },
    IL: {
      name: "Israel",
      native: "יִשְׂרָאֵל",
      phone: "972",
      continent: "AS",
      capital: "Jerusalem",
      currency: "ILS",
      languages: "he,ar"
    },
    IM: {
      name: "Isle of Man",
      native: "Isle of Man",
      phone: "44",
      continent: "EU",
      capital: "Douglas",
      currency: "GBP",
      languages: "en,gv"
    },
    IN: {
      name: "India",
      native: "भारत",
      phone: "91",
      continent: "AS",
      capital: "New Delhi",
      currency: "INR",
      languages: "hi,en"
    },
    IO: {
      name: "British Indian Ocean Territory",
      native: "British Indian Ocean Territory",
      phone: "246",
      continent: "AS",
      capital: "Diego Garcia",
      currency: "USD",
      languages: "en"
    },
    IQ: {
      name: "Iraq",
      native: "العراق",
      phone: "964",
      continent: "AS",
      capital: "Baghdad",
      currency: "IQD",
      languages: "ar,ku"
    },
    IR: {
      name: "Iran",
      native: "ایران",
      phone: "98",
      continent: "AS",
      capital: "Tehran",
      currency: "IRR",
      languages: "fa"
    },
    IS: {
      name: "Iceland",
      native: "Ísland",
      phone: "354",
      continent: "EU",
      capital: "Reykjavik",
      currency: "ISK",
      languages: "is"
    },
    IT: {
      name: "Italy",
      native: "Italia",
      phone: "39",
      continent: "EU",
      capital: "Rome",
      currency: "EUR",
      languages: "it"
    },
    JE: {
      name: "Jersey",
      native: "Jersey",
      phone: "44",
      continent: "EU",
      capital: "Saint Helier",
      currency: "GBP",
      languages: "en,fr"
    },
    JM: {
      name: "Jamaica",
      native: "Jamaica",
      phone: "1876",
      continent: "NA",
      capital: "Kingston",
      currency: "JMD",
      languages: "en"
    },
    JO: {
      name: "Jordan",
      native: "الأردن",
      phone: "962",
      continent: "AS",
      capital: "Amman",
      currency: "JOD",
      languages: "ar"
    },
    JP: {
      name: "Japan",
      native: "日本",
      phone: "81",
      continent: "AS",
      capital: "Tokyo",
      currency: "JPY",
      languages: "ja"
    },
    KE: {
      name: "Kenya",
      native: "Kenya",
      phone: "254",
      continent: "AF",
      capital: "Nairobi",
      currency: "KES",
      languages: "en,sw"
    },
    KG: {
      name: "Kyrgyzstan",
      native: "Кыргызстан",
      phone: "996",
      continent: "AS",
      capital: "Bishkek",
      currency: "KGS",
      languages: "ky,ru"
    },
    KH: {
      name: "Cambodia",
      native: "Kâmpŭchéa",
      phone: "855",
      continent: "AS",
      capital: "Phnom Penh",
      currency: "KHR",
      languages: "km"
    },
    KI: {
      name: "Kiribati",
      native: "Kiribati",
      phone: "686",
      continent: "OC",
      capital: "South Tarawa",
      currency: "AUD",
      languages: "en"
    },
    KM: {
      name: "Comoros",
      native: "Komori",
      phone: "269",
      continent: "AF",
      capital: "Moroni",
      currency: "KMF",
      languages: "ar,fr"
    },
    KN: {
      name: "Saint Kitts and Nevis",
      native: "Saint Kitts and Nevis",
      phone: "1869",
      continent: "NA",
      capital: "Basseterre",
      currency: "XCD",
      languages: "en"
    },
    KP: {
      name: "North Korea",
      native: "북한",
      phone: "850",
      continent: "AS",
      capital: "Pyongyang",
      currency: "KPW",
      languages: "ko"
    },
    KR: {
      name: "South Korea",
      native: "대한민국",
      phone: "82",
      continent: "AS",
      capital: "Seoul",
      currency: "KRW",
      languages: "ko"
    },
    KW: {
      name: "Kuwait",
      native: "الكويت",
      phone: "965",
      continent: "AS",
      capital: "Kuwait City",
      currency: "KWD",
      languages: "ar"
    },
    KY: {
      name: "Cayman Islands",
      native: "Cayman Islands",
      phone: "1345",
      continent: "NA",
      capital: "George Town",
      currency: "KYD",
      languages: "en"
    },
    KZ: {
      name: "Kazakhstan",
      native: "Қазақстан",
      phone: "76,77",
      continent: "AS",
      capital: "Astana",
      currency: "KZT",
      languages: "kk,ru"
    },
    LA: {
      name: "Laos",
      native: "ສປປລາວ",
      phone: "856",
      continent: "AS",
      capital: "Vientiane",
      currency: "LAK",
      languages: "lo"
    },
    LB: {
      name: "Lebanon",
      native: "لبنان",
      phone: "961",
      continent: "AS",
      capital: "Beirut",
      currency: "LBP",
      languages: "ar,fr"
    },
    LC: {
      name: "Saint Lucia",
      native: "Saint Lucia",
      phone: "1758",
      continent: "NA",
      capital: "Castries",
      currency: "XCD",
      languages: "en"
    },
    LI: {
      name: "Liechtenstein",
      native: "Liechtenstein",
      phone: "423",
      continent: "EU",
      capital: "Vaduz",
      currency: "CHF",
      languages: "de"
    },
    LK: {
      name: "Sri Lanka",
      native: "śrī laṃkāva",
      phone: "94",
      continent: "AS",
      capital: "Colombo",
      currency: "LKR",
      languages: "si,ta"
    },
    LR: {
      name: "Liberia",
      native: "Liberia",
      phone: "231",
      continent: "AF",
      capital: "Monrovia",
      currency: "LRD",
      languages: "en"
    },
    LS: {
      name: "Lesotho",
      native: "Lesotho",
      phone: "266",
      continent: "AF",
      capital: "Maseru",
      currency: "LSL,ZAR",
      languages: "en,st"
    },
    LT: {
      name: "Lithuania",
      native: "Lietuva",
      phone: "370",
      continent: "EU",
      capital: "Vilnius",
      currency: "LTL",
      languages: "lt"
    },
    LU: {
      name: "Luxembourg",
      native: "Luxembourg",
      phone: "352",
      continent: "EU",
      capital: "Luxembourg",
      currency: "EUR",
      languages: "fr,de,lb"
    },
    LV: {
      name: "Latvia",
      native: "Latvija",
      phone: "371",
      continent: "EU",
      capital: "Riga",
      currency: "EUR",
      languages: "lv"
    },
    LY: {
      name: "Libya",
      native: "‏ليبيا",
      phone: "218",
      continent: "AF",
      capital: "Tripoli",
      currency: "LYD",
      languages: "ar"
    },
    MA: {
      name: "Morocco",
      native: "المغرب",
      phone: "212",
      continent: "AF",
      capital: "Rabat",
      currency: "MAD",
      languages: "ar"
    },
    MC: {
      name: "Monaco",
      native: "Monaco",
      phone: "377",
      continent: "EU",
      capital: "Monaco",
      currency: "EUR",
      languages: "fr"
    },
    MD: {
      name: "Moldova",
      native: "Moldova",
      phone: "373",
      continent: "EU",
      capital: "Chișinău",
      currency: "MDL",
      languages: "ro"
    },
    ME: {
      name: "Montenegro",
      native: "Црна Гора",
      phone: "382",
      continent: "EU",
      capital: "Podgorica",
      currency: "EUR",
      languages: "sr,bs,sq,hr"
    },
    MF: {
      name: "Saint Martin",
      native: "Saint-Martin",
      phone: "590",
      continent: "NA",
      capital: "Marigot",
      currency: "EUR",
      languages: "en,fr,nl"
    },
    MG: {
      name: "Madagascar",
      native: "Madagasikara",
      phone: "261",
      continent: "AF",
      capital: "Antananarivo",
      currency: "MGA",
      languages: "fr,mg"
    },
    MH: {
      name: "Marshall Islands",
      native: "M̧ajeļ",
      phone: "692",
      continent: "OC",
      capital: "Majuro",
      currency: "USD",
      languages: "en,mh"
    },
    MK: {
      name: "Macedonia",
      native: "Македонија",
      phone: "389",
      continent: "EU",
      capital: "Skopje",
      currency: "MKD",
      languages: "mk"
    },
    ML: {
      name: "Mali",
      native: "Mali",
      phone: "223",
      continent: "AF",
      capital: "Bamako",
      currency: "XOF",
      languages: "fr"
    },
    MM: {
      name: "Myanmar [Burma]",
      native: "Myanma",
      phone: "95",
      continent: "AS",
      capital: "Naypyidaw",
      currency: "MMK",
      languages: "my"
    },
    MN: {
      name: "Mongolia",
      native: "Монгол улс",
      phone: "976",
      continent: "AS",
      capital: "Ulan Bator",
      currency: "MNT",
      languages: "mn"
    },
    MO: {
      name: "Macao",
      native: "澳門",
      phone: "853",
      continent: "AS",
      capital: "",
      currency: "MOP",
      languages: "zh,pt"
    },
    MP: {
      name: "Northern Mariana Islands",
      native: "Northern Mariana Islands",
      phone: "1670",
      continent: "OC",
      capital: "Saipan",
      currency: "USD",
      languages: "en,ch"
    },
    MQ: {
      name: "Martinique",
      native: "Martinique",
      phone: "596",
      continent: "NA",
      capital: "Fort-de-France",
      currency: "EUR",
      languages: "fr"
    },
    MR: {
      name: "Mauritania",
      native: "موريتانيا",
      phone: "222",
      continent: "AF",
      capital: "Nouakchott",
      currency: "MRO",
      languages: "ar"
    },
    MS: {
      name: "Montserrat",
      native: "Montserrat",
      phone: "1664",
      continent: "NA",
      capital: "Plymouth",
      currency: "XCD",
      languages: "en"
    },
    MT: {
      name: "Malta",
      native: "Malta",
      phone: "356",
      continent: "EU",
      capital: "Valletta",
      currency: "EUR",
      languages: "mt,en"
    },
    MU: {
      name: "Mauritius",
      native: "Maurice",
      phone: "230",
      continent: "AF",
      capital: "Port Louis",
      currency: "MUR",
      languages: "en"
    },
    MV: {
      name: "Maldives",
      native: "Maldives",
      phone: "960",
      continent: "AS",
      capital: "Malé",
      currency: "MVR",
      languages: "dv"
    },
    MW: {
      name: "Malawi",
      native: "Malawi",
      phone: "265",
      continent: "AF",
      capital: "Lilongwe",
      currency: "MWK",
      languages: "en,ny"
    },
    MX: {
      name: "Mexico",
      native: "México",
      phone: "52",
      continent: "NA",
      capital: "Mexico City",
      currency: "MXN",
      languages: "es"
    },
    MY: {
      name: "Malaysia",
      native: "Malaysia",
      phone: "60",
      continent: "AS",
      capital: "Kuala Lumpur",
      currency: "MYR",
      languages: ""
    },
    MZ: {
      name: "Mozambique",
      native: "Moçambique",
      phone: "258",
      continent: "AF",
      capital: "Maputo",
      currency: "MZN",
      languages: "pt"
    },
    NA: {
      name: "Namibia",
      native: "Namibia",
      phone: "264",
      continent: "AF",
      capital: "Windhoek",
      currency: "NAD,ZAR",
      languages: "en,af"
    },
    NC: {
      name: "New Caledonia",
      native: "Nouvelle-Calédonie",
      phone: "687",
      continent: "OC",
      capital: "Nouméa",
      currency: "XPF",
      languages: "fr"
    },
    NE: {
      name: "Niger",
      native: "Niger",
      phone: "227",
      continent: "AF",
      capital: "Niamey",
      currency: "XOF",
      languages: "fr"
    },
    NF: {
      name: "Norfolk Island",
      native: "Norfolk Island",
      phone: "672",
      continent: "OC",
      capital: "Kingston",
      currency: "AUD",
      languages: "en"
    },
    NG: {
      name: "Nigeria",
      native: "Nigeria",
      phone: "234",
      continent: "AF",
      capital: "Abuja",
      currency: "NGN",
      languages: "en"
    },
    NI: {
      name: "Nicaragua",
      native: "Nicaragua",
      phone: "505",
      continent: "NA",
      capital: "Managua",
      currency: "NIO",
      languages: "es"
    },
    NL: {
      name: "Netherlands",
      native: "Nederland",
      phone: "31",
      continent: "EU",
      capital: "Amsterdam",
      currency: "EUR",
      languages: "nl"
    },
    NO: {
      name: "Norway",
      native: "Norge",
      phone: "47",
      continent: "EU",
      capital: "Oslo",
      currency: "NOK",
      languages: "no,nb,nn"
    },
    NP: {
      name: "Nepal",
      native: "नपल",
      phone: "977",
      continent: "AS",
      capital: "Kathmandu",
      currency: "NPR",
      languages: "ne"
    },
    NR: {
      name: "Nauru",
      native: "Nauru",
      phone: "674",
      continent: "OC",
      capital: "Yaren",
      currency: "AUD",
      languages: "en,na"
    },
    NU: {
      name: "Niue",
      native: "Niuē",
      phone: "683",
      continent: "OC",
      capital: "Alofi",
      currency: "NZD",
      languages: "en"
    },
    NZ: {
      name: "New Zealand",
      native: "New Zealand",
      phone: "64",
      continent: "OC",
      capital: "Wellington",
      currency: "NZD",
      languages: "en,mi"
    },
    OM: {
      name: "Oman",
      native: "عمان",
      phone: "968",
      continent: "AS",
      capital: "Muscat",
      currency: "OMR",
      languages: "ar"
    },
    PA: {
      name: "Panama",
      native: "Panamá",
      phone: "507",
      continent: "NA",
      capital: "Panama City",
      currency: "PAB,USD",
      languages: "es"
    },
    PE: {
      name: "Peru",
      native: "Perú",
      phone: "51",
      continent: "SA",
      capital: "Lima",
      currency: "PEN",
      languages: "es"
    },
    PF: {
      name: "French Polynesia",
      native: "Polynésie française",
      phone: "689",
      continent: "OC",
      capital: "Papeetē",
      currency: "XPF",
      languages: "fr"
    },
    PG: {
      name: "Papua New Guinea",
      native: "Papua Niugini",
      phone: "675",
      continent: "OC",
      capital: "Port Moresby",
      currency: "PGK",
      languages: "en"
    },
    PH: {
      name: "Philippines",
      native: "Pilipinas",
      phone: "63",
      continent: "AS",
      capital: "Manila",
      currency: "PHP",
      languages: "en"
    },
    PK: {
      name: "Pakistan",
      native: "Pakistan",
      phone: "92",
      continent: "AS",
      capital: "Islamabad",
      currency: "PKR",
      languages: "en,ur"
    },
    PL: {
      name: "Poland",
      native: "Polska",
      phone: "48",
      continent: "EU",
      capital: "Warsaw",
      currency: "PLN",
      languages: "pl"
    },
    PM: {
      name: "Saint Pierre and Miquelon",
      native: "Saint-Pierre-et-Miquelon",
      phone: "508",
      continent: "NA",
      capital: "Saint-Pierre",
      currency: "EUR",
      languages: "fr"
    },
    PN: {
      name: "Pitcairn Islands",
      native: "Pitcairn Islands",
      phone: "64",
      continent: "OC",
      capital: "Adamstown",
      currency: "NZD",
      languages: "en"
    },
    PR: {
      name: "Puerto Rico",
      native: "Puerto Rico",
      phone: "1787,1939",
      continent: "NA",
      capital: "San Juan",
      currency: "USD",
      languages: "es,en"
    },
    PS: {
      name: "Palestine",
      native: "فلسطين",
      phone: "970",
      continent: "AS",
      capital: "Ramallah",
      currency: "ILS",
      languages: "ar"
    },
    PT: {
      name: "Portugal",
      native: "Portugal",
      phone: "351",
      continent: "EU",
      capital: "Lisbon",
      currency: "EUR",
      languages: "pt"
    },
    PW: {
      name: "Palau",
      native: "Palau",
      phone: "680",
      continent: "OC",
      capital: "Ngerulmud",
      currency: "USD",
      languages: "en"
    },
    PY: {
      name: "Paraguay",
      native: "Paraguay",
      phone: "595",
      continent: "SA",
      capital: "Asunción",
      currency: "PYG",
      languages: "es,gn"
    },
    QA: {
      name: "Qatar",
      native: "قطر",
      phone: "974",
      continent: "AS",
      capital: "Doha",
      currency: "QAR",
      languages: "ar"
    },
    RE: {
      name: "Réunion",
      native: "La Réunion",
      phone: "262",
      continent: "AF",
      capital: "Saint-Denis",
      currency: "EUR",
      languages: "fr"
    },
    RO: {
      name: "Romania",
      native: "România",
      phone: "40",
      continent: "EU",
      capital: "Bucharest",
      currency: "RON",
      languages: "ro"
    },
    RS: {
      name: "Serbia",
      native: "Србија",
      phone: "381",
      continent: "EU",
      capital: "Belgrade",
      currency: "RSD",
      languages: "sr"
    },
    RU: {
      name: "Russia",
      native: "Россия",
      phone: "7",
      continent: "EU",
      capital: "Moscow",
      currency: "RUB",
      languages: "ru"
    },
    RW: {
      name: "Rwanda",
      native: "Rwanda",
      phone: "250",
      continent: "AF",
      capital: "Kigali",
      currency: "RWF",
      languages: "rw,en,fr"
    },
    SA: {
      name: "Saudi Arabia",
      native: "العربية السعودية",
      phone: "966",
      continent: "AS",
      capital: "Riyadh",
      currency: "SAR",
      languages: "ar"
    },
    SB: {
      name: "Solomon Islands",
      native: "Solomon Islands",
      phone: "677",
      continent: "OC",
      capital: "Honiara",
      currency: "SBD",
      languages: "en"
    },
    SC: {
      name: "Seychelles",
      native: "Seychelles",
      phone: "248",
      continent: "AF",
      capital: "Victoria",
      currency: "SCR",
      languages: "fr,en"
    },
    SD: {
      name: "Sudan",
      native: "السودان",
      phone: "249",
      continent: "AF",
      capital: "Khartoum",
      currency: "SDG",
      languages: "ar,en"
    },
    SE: {
      name: "Sweden",
      native: "Sverige",
      phone: "46",
      continent: "EU",
      capital: "Stockholm",
      currency: "SEK",
      languages: "sv"
    },
    SG: {
      name: "Singapore",
      native: "Singapore",
      phone: "65",
      continent: "AS",
      capital: "Singapore",
      currency: "SGD",
      languages: "en,ms,ta,zh"
    },
    SH: {
      name: "Saint Helena",
      native: "Saint Helena",
      phone: "290",
      continent: "AF",
      capital: "Jamestown",
      currency: "SHP",
      languages: "en"
    },
    SI: {
      name: "Slovenia",
      native: "Slovenija",
      phone: "386",
      continent: "EU",
      capital: "Ljubljana",
      currency: "EUR",
      languages: "sl"
    },
    SJ: {
      name: "Svalbard and Jan Mayen",
      native: "Svalbard og Jan Mayen",
      phone: "4779",
      continent: "EU",
      capital: "Longyearbyen",
      currency: "NOK",
      languages: "no"
    },
    SK: {
      name: "Slovakia",
      native: "Slovensko",
      phone: "421",
      continent: "EU",
      capital: "Bratislava",
      currency: "EUR",
      languages: "sk"
    },
    SL: {
      name: "Sierra Leone",
      native: "Sierra Leone",
      phone: "232",
      continent: "AF",
      capital: "Freetown",
      currency: "SLL",
      languages: "en"
    },
    SM: {
      name: "San Marino",
      native: "San Marino",
      phone: "378",
      continent: "EU",
      capital: "City of San Marino",
      currency: "EUR",
      languages: "it"
    },
    SN: {
      name: "Senegal",
      native: "Sénégal",
      phone: "221",
      continent: "AF",
      capital: "Dakar",
      currency: "XOF",
      languages: "fr"
    },
    SO: {
      name: "Somalia",
      native: "Soomaaliya",
      phone: "252",
      continent: "AF",
      capital: "Mogadishu",
      currency: "SOS",
      languages: "so,ar"
    },
    SR: {
      name: "Suriname",
      native: "Suriname",
      phone: "597",
      continent: "SA",
      capital: "Paramaribo",
      currency: "SRD",
      languages: "nl"
    },
    SS: {
      name: "South Sudan",
      native: "South Sudan",
      phone: "211",
      continent: "AF",
      capital: "Juba",
      currency: "SSP",
      languages: "en"
    },
    ST: {
      name: "São Tomé and Príncipe",
      native: "São Tomé e Príncipe",
      phone: "239",
      continent: "AF",
      capital: "São Tomé",
      currency: "STD",
      languages: "pt"
    },
    SV: {
      name: "El Salvador",
      native: "El Salvador",
      phone: "503",
      continent: "NA",
      capital: "San Salvador",
      currency: "SVC,USD",
      languages: "es"
    },
    SX: {
      name: "Sint Maarten",
      native: "Sint Maarten",
      phone: "1721",
      continent: "NA",
      capital: "Philipsburg",
      currency: "ANG",
      languages: "nl,en"
    },
    SY: {
      name: "Syria",
      native: "سوريا",
      phone: "963",
      continent: "AS",
      capital: "Damascus",
      currency: "SYP",
      languages: "ar"
    },
    SZ: {
      name: "Swaziland",
      native: "Swaziland",
      phone: "268",
      continent: "AF",
      capital: "Lobamba",
      currency: "SZL",
      languages: "en,ss"
    },
    TC: {
      name: "Turks and Caicos Islands",
      native: "Turks and Caicos Islands",
      phone: "1649",
      continent: "NA",
      capital: "Cockburn Town",
      currency: "USD",
      languages: "en"
    },
    TD: {
      name: "Chad",
      native: "Tchad",
      phone: "235",
      continent: "AF",
      capital: "N'Djamena",
      currency: "XAF",
      languages: "fr,ar"
    },
    TF: {
      name: "French Southern Territories",
      native: "Territoire des Terres australes et antarctiques fr",
      phone: "",
      continent: "AN",
      capital: "Port-aux-Français",
      currency: "EUR",
      languages: "fr"
    },
    TG: {
      name: "Togo",
      native: "Togo",
      phone: "228",
      continent: "AF",
      capital: "Lomé",
      currency: "XOF",
      languages: "fr"
    },
    TH: {
      name: "Thailand",
      native: "ประเทศไทย",
      phone: "66",
      continent: "AS",
      capital: "Bangkok",
      currency: "THB",
      languages: "th"
    },
    TJ: {
      name: "Tajikistan",
      native: "Тоҷикистон",
      phone: "992",
      continent: "AS",
      capital: "Dushanbe",
      currency: "TJS",
      languages: "tg,ru"
    },
    TK: {
      name: "Tokelau",
      native: "Tokelau",
      phone: "690",
      continent: "OC",
      capital: "Fakaofo",
      currency: "NZD",
      languages: "en"
    },
    TL: {
      name: "East Timor",
      native: "Timor-Leste",
      phone: "670",
      continent: "OC",
      capital: "Dili",
      currency: "USD",
      languages: "pt"
    },
    TM: {
      name: "Turkmenistan",
      native: "Türkmenistan",
      phone: "993",
      continent: "AS",
      capital: "Ashgabat",
      currency: "TMT",
      languages: "tk,ru"
    },
    TN: {
      name: "Tunisia",
      native: "تونس",
      phone: "216",
      continent: "AF",
      capital: "Tunis",
      currency: "TND",
      languages: "ar"
    },
    TO: {
      name: "Tonga",
      native: "Tonga",
      phone: "676",
      continent: "OC",
      capital: "Nuku'alofa",
      currency: "TOP",
      languages: "en,to"
    },
    TR: {
      name: "Turkey",
      native: "Türkiye",
      phone: "90",
      continent: "AS",
      capital: "Ankara",
      currency: "TRY",
      languages: "tr"
    },
    TT: {
      name: "Trinidad and Tobago",
      native: "Trinidad and Tobago",
      phone: "1868",
      continent: "NA",
      capital: "Port of Spain",
      currency: "TTD",
      languages: "en"
    },
    TV: {
      name: "Tuvalu",
      native: "Tuvalu",
      phone: "688",
      continent: "OC",
      capital: "Funafuti",
      currency: "AUD",
      languages: "en"
    },
    TW: {
      name: "Taiwan",
      native: "臺灣",
      phone: "886",
      continent: "AS",
      capital: "Taipei",
      currency: "TWD",
      languages: "zh"
    },
    TZ: {
      name: "Tanzania",
      native: "Tanzania",
      phone: "255",
      continent: "AF",
      capital: "Dodoma",
      currency: "TZS",
      languages: "sw,en"
    },
    UA: {
      name: "Ukraine",
      native: "Україна",
      phone: "380",
      continent: "EU",
      capital: "Kiev",
      currency: "UAH",
      languages: "uk"
    },
    UG: {
      name: "Uganda",
      native: "Uganda",
      phone: "256",
      continent: "AF",
      capital: "Kampala",
      currency: "UGX",
      languages: "en,sw"
    },
    UM: {
      name: "U.S. Minor Outlying Islands",
      native: "United States Minor Outlying Islands",
      phone: "",
      continent: "OC",
      capital: "",
      currency: "USD",
      languages: "en"
    },
    US: {
      name: "United States",
      native: "United States",
      phone: "1",
      continent: "NA",
      capital: "Washington D.C.",
      currency: "USD,USN,USS",
      languages: "en"
    },
    UY: {
      name: "Uruguay",
      native: "Uruguay",
      phone: "598",
      continent: "SA",
      capital: "Montevideo",
      currency: "UYI,UYU",
      languages: "es"
    },
    UZ: {
      name: "Uzbekistan",
      native: "O‘zbekiston",
      phone: "998",
      continent: "AS",
      capital: "Tashkent",
      currency: "UZS",
      languages: "uz,ru"
    },
    VA: {
      name: "Vatican City",
      native: "Vaticano",
      phone: "39066,379",
      continent: "EU",
      capital: "Vatican City",
      currency: "EUR",
      languages: "it,la"
    },
    VC: {
      name: "Saint Vincent and the Grenadines",
      native: "Saint Vincent and the Grenadines",
      phone: "1784",
      continent: "NA",
      capital: "Kingstown",
      currency: "XCD",
      languages: "en"
    },
    VE: {
      name: "Venezuela",
      native: "Venezuela",
      phone: "58",
      continent: "SA",
      capital: "Caracas",
      currency: "VEF",
      languages: "es"
    },
    VG: {
      name: "British Virgin Islands",
      native: "British Virgin Islands",
      phone: "1284",
      continent: "NA",
      capital: "Road Town",
      currency: "USD",
      languages: "en"
    },
    VI: {
      name: "U.S. Virgin Islands",
      native: "United States Virgin Islands",
      phone: "1340",
      continent: "NA",
      capital: "Charlotte Amalie",
      currency: "USD",
      languages: "en"
    },
    VN: {
      name: "Vietnam",
      native: "Việt Nam",
      phone: "84",
      continent: "AS",
      capital: "Hanoi",
      currency: "VND",
      languages: "vi"
    },
    VU: {
      name: "Vanuatu",
      native: "Vanuatu",
      phone: "678",
      continent: "OC",
      capital: "Port Vila",
      currency: "VUV",
      languages: "bi,en,fr"
    },
    WF: {
      name: "Wallis and Futuna",
      native: "Wallis et Futuna",
      phone: "681",
      continent: "OC",
      capital: "Mata-Utu",
      currency: "XPF",
      languages: "fr"
    },
    WS: {
      name: "Samoa",
      native: "Samoa",
      phone: "685",
      continent: "OC",
      capital: "Apia",
      currency: "WST",
      languages: "sm,en"
    },
    XK: {
      name: "Kosovo",
      native: "Republika e Kosovës",
      phone: "377,381,386",
      continent: "EU",
      capital: "Pristina",
      currency: "EUR",
      languages: "sq,sr"
    },
    YE: {
      name: "Yemen",
      native: "اليَمَن",
      phone: "967",
      continent: "AS",
      capital: "Sana'a",
      currency: "YER",
      languages: "ar"
    },
    YT: {
      name: "Mayotte",
      native: "Mayotte",
      phone: "262",
      continent: "AF",
      capital: "Mamoudzou",
      currency: "EUR",
      languages: "fr"
    },
    ZA: {
      name: "South Africa",
      native: "South Africa",
      phone: "27",
      continent: "AF",
      capital: "Pretoria",
      currency: "ZAR",
      languages: "af,en,nr,st,ss,tn,ts,ve,xh,zu"
    },
    ZM: {
      name: "Zambia",
      native: "Zambia",
      phone: "260",
      continent: "AF",
      capital: "Lusaka",
      currency: "ZMK",
      languages: "en"
    },
    ZW: {
      name: "Zimbabwe",
      native: "Zimbabwe",
      phone: "263",
      continent: "AF",
      capital: "Harare",
      currency: "ZWL",
      languages: "en,sn,nd"
    }
  }
}, Le = [
  "BE",
  "BG",
  "CZ",
  "DK",
  "DE",
  "EE",
  "IE",
  "GR",
  "ES",
  "FR",
  "HR",
  "IT",
  "CY",
  "LV",
  "LT",
  "LU",
  "HU",
  "MT",
  "NL",
  "AT",
  "PL",
  "PT",
  "RO",
  "SI",
  "SK",
  "FI",
  "SE",
  "GB"
], je = {
  GR: { prefix: "EL" }
}, ba = {
  getFilteredCountryList: () => {
    const e = [];
    for (let n = 0; n < Le.length; n++) {
      const a = Le[n], t = Ua.countries[a];
      t && e.push({
        native: t.native,
        name: t.name,
        //@ts-ignore
        vatPrefix: je[a] ? je[a].prefix : a,
        code: a
      });
    }
    return e.sort((n, a) => {
      const t = n.name.toLowerCase(), r = a.name.toLowerCase();
      return t < r ? -1 : t > r ? 1 : 0;
    }), e.map((n) => {
      const a = {};
      return a[n.code] = n, a;
    });
  }
};
var Ca = /* @__PURE__ */ ((e) => (e[e.redOwner = 0] = "redOwner", e[e.redManager = 1] = "redManager", e[e.redProjectExecutive = 2] = "redProjectExecutive", e[e.redProjectAgent = 3] = "redProjectAgent", e))(Ca || {});
const Ge = {
  update: (e, n, a) => l(`vaults/${e}`, n, a),
  updateSubscription: (e, n, a) => m(
    `vaults/${e}/updatesubscription`,
    n,
    a
  ),
  getInfo: (e, n, a) => o(`vaults/${e}/info`, a, n),
  /**
   * Get vault user permissions
   * @param {string} vaultId
   * @param {string} userId
   * @param {string} sessionToken
   */
  getUserPermissions: (e, n, a) => o(
    `vaults/${e}/userPermissions/${n}`,
    {},
    a
  ),
  /**
   * Get access token for storage
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getstorageaccesstoken
   * @param {string} vaultId
   * @param {string} sessionToken
   */
  getUploadAccessToken: (e, n) => o(
    `vaults/${e}/catalogue/storage/token`,
    {},
    n
  ),
  /**
   * Create a vault from Product-led registration page
   * Documentation is to be generated
   * @param {Object} params
   */
  createFromProductLed: (e) => m(
    "entities/aroundmedia/vaults/createVaultFromProductLed",
    e
  ),
  /**
   * Create a contact in Hubspot after product-led registration is done
   * @param {string} portalId
   * @param {string} formId
   * @param {Object} params
   */
  submitHubspotProductLedRegistration: (e, n, a) => N.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${e}/${n}`,
    a
  ),
  /**
   * Get unit slots usage report for a vault
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getunitslotusagereport_1
   */
  getUnitSlotUsageReport: (e, n) => o(
    `vaults/${e}/getUnitSlotUsageReport`,
    {},
    n
  ),
  /**
   * Get Per Unit Plans
   * @param {string} sessionToken
   */
  getPerUnitPlans: (e) => o(
    "subscriptionplans/getPerUnitActivePlans",
    {},
    e
  ),
  /**
   * Get payment sources
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getpaymentsources
   */
  getPaymentSources: (e, n) => o(
    `vaults/${e}/getPaymentSources`,
    {},
    n
  ),
  /**
   * Get payments history
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_paymenthistory
   */
  getPaymentsHistory: (e, n) => o(
    `vaults/${e}/getPaymentHistory`,
    {},
    n
  ),
  /**
   * Get ChargeBee invoice PDF
   * Documentation is to be added
   */
  getChargeBeeInvoicePdf: (e, n, a) => o(
    `vaults/${e}/getInvoicePdfUrl/${n}`,
    {},
    a
  ),
  /**
   * Change subscription plan on next renewal date
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_changeplanonnextrenewaldate
   * @param {string} sessionToken
   * @param {Object} params
   */
  changePlanOnNextRenewalDate: (e, n) => l("vaults/changePlanOnNextRenewalDate", n, e),
  /**
   * Get change plan on next renewal date info
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getchangeplanonnextrenewaldate
   * @param {string} vaultId
   * @param {string} sessionToken
   */
  getPlanOnNextRenewalDate: (e, n) => o(
    `vaults/changePlanOnNextRenewalDate/${e}`,
    {},
    n
  ),
  /**
   * Get estimates for update vault subscription
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updatesubscriptionestimates
   * @param {string} vaultId
   * @param {Object} body
   * @param {string} sessionToken
   */
  getEstimatesForUpdateVaultSubscription: (e, n, a) => m(
    `vaults/${e}/updatesubscription/estimates`,
    n,
    a
  ),
  /**
   * delete queued change plan on next renewal date
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_cancelchangeplanonnextrenewal
   * @param {string} vaultId
   * @param {string} sessionToken
   */
  cancelChangePlanOnNextRenewal: (e, n) => E(
    `vaults/cancelChangePlanOnNextRenewal/${e}`,
    n
  ),
  /**
   * Get estimates for upcoming invoice
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_upcominginvoiceestimates
   * @param {string} vaultId
   * @param {string} sessionToken
   */
  getEstimatesForUpcomingInvoice: (e, n) => o(
    `vaults/${e}/upcominginvoiceestimate`,
    {},
    n
  ),
  /**
   * Verify email
   * Documentation is to be generated
   * @param {String} confirmationToken
   * @param {String} vaultObjectId
   */
  verifyEmail: (e, n) => m("vaults/confirmEmailAddress", {
    token: e,
    vaultObjectId: n
  }),
  /**
   * Resend confirmation email
   * Documentation is to be generated
   * @param {String} vaultId
   * @param {String} sessionToken
   */
  resendConfirmationEmail: (e, n) => m(
    `vaults/${e}/resendConfirmationEmail`,
    {},
    n
  ),
  /**
   * Request content
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_requestadditionalcontent
   * @param {string} vaultId
   * @param {string} projectId
   * @param {string} sessionToken
   */
  requestContent: (e, n, a) => o(
    `order/requestAdditionalContent?vaultObjectId=${e}&projectObjectId=${n}`,
    {},
    a
  ),
  /**
   * Check vault subscription
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_checksubscription
   */
  checkSubscription: (e, n) => o(
    `vaults/${e}/checksubscription`,
    {},
    n
  ),
  /**
   * Get all users that can access given vault
   * @param vaultId
   * @param sessionToken
   * @returns
   */
  getAllUsersThatCanAccess: (e, n) => o(`vaults/${e}/users`, {}, n),
  /**
   * Check the VAT
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_checkvatget
   */
  checkVat: (e, n, a) => o(
    `/vaults/checkvat?country=${e}&number=${n}`,
    {},
    a
  ),
  /**
   * Get the permissions of an invitation on the vault
   * https://devapiv2.vr-tual.media/asciidoc/?_=aabbcc#_getinvitationpermissions
   */
  getPermissionsOfInvitation: (e, n, a) => o(
    `vaults/${e}/invitations/${n}`,
    {},
    a
  ),
  /**
   * Get the permissions of a user on the vault
   * https://devapiv2.vr-tual.media/asciidoc/?_=aabbcc#_getpermissions
   */
  getPermissionOfUser: (e, n, a) => o(
    `vaults/${e}/users/${n}`,
    {},
    a
  ),
  /**
   * Get the allowed permission roles for the vault
   * https://devapiv2.vr-tual.media/asciidoc/?_=aabbcc#_getallowedpermissionroles_1
   */
  getAllowedPermissionRoles: (e, n) => o(
    `vaults/${e}/permissionroles`,
    {},
    n
  ),
  /**
   * DEPRECATED
   * Search data in catalogues
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_search_1
   */
  searchDataInCatalogues: (e, n, a) => o(
    `entities/${e}/catalogues`,
    n,
    a
  ),
  /**
   * Invite user to the vault
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_inviteuser_2
   */
  inviteUser: (e, n, a, t = !1) => m(
    `vaults/${e}/users?skipInvitationMail=${t}`,
    n,
    a
  ),
  /**
   * Invite multiple users to the vault
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_inviteuser_2
   */
  inviteUserBulk: (e, n, a, t = !1) => m(
    `vaults/${e}/users/bulk?skipInvitationMail=${t}`,
    n,
    a
  ),
  /**
   * Kick/remove a user from a vault
   * https://devapiv2.vr-tual.media/asciidoc/?_=aabbcc#_kick
   */
  kickUser: (e, n, a) => E(
    `vaults/${e}/users/${n}`,
    a
  ),
  /**
   * Uninvite a user, remove the invitation
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_uninviteuser_1
   */
  uninviteUser: (e, n, a) => E(
    `vaults/${e}/users/uninvite?invitationUuid=${n}`,
    a
  ),
  /**
   * Update the permissions for a given user on a vault
   * https://devapiv2.vr-tual.media/asciidoc/#_updateuser_1
   */
  updateUserPermissions: (e, n, a, t) => l(
    `vaults/${e}/users/${n}`,
    a,
    t
  ),
  /**
   * Get vault feature flags
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getvaultfeatureflaglist
   */
  getFeatureFlags: (e) => o(`vaults/vaultFeatureFlagList/${e}`, {}),
  /**
   * Get vault contact person
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getcontactperson
   */
  getContactPerson: (e) => o(`vaults/getContactPerson/${e}`, {}),
  /**
   * Get portfolio settings
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getbyvaultid
   */
  getPortfolioSettings: (e) => e ? o(`marketplacesettings/getByVaultId/${e}`, {
    vaultObjectId: e
  }) : Promise.reject(
    new Error(
      `Can't send getMarketplaceSettings call: invalid vaultId. (vaultId=${e})`
    )
  ),
  fetchAvailableCountries: () => Promise.resolve(ba.getFilteredCountryList()),
  mockgetAllUsersThatCanAccess: (e, n = {}) => (Ge.getAllUsersThatCanAccess.mockReset(), Ge.getAllUsersThatCanAccess.mockImplementation(
    (a, t) => {
      const r = n?.error, c = r ? {
        status: n.error.httpStatus,
        data: {
          code: n.error.errorCode,
          message: n.error.errorMessage
        }
      } : {
        status: 200,
        data: {
          userDictionary: {
            // For now, no need to have users with different roles, just assume all users are redOwner
            redOwner: e
          }
        }
      };
      return n?.timeToCompleteCall ? new Promise((g, S) => {
        setTimeout(
          () => r ? S(c) : g(c),
          n.timeToCompleteCall
        );
      }) : r ? Promise.reject(c) : Promise.resolve(c);
    }
  ))
}, _a = {
  /**
   * Assign user to a board ticket
   *
   */
  assign: (e, n, a) => l(
    `boardticket/assign/${e}`,
    n,
    a
  ),
  /**
   * Move a board ticket from one column to another
   */
  move: (e, n) => l(
    "boardticket/move/moveBoardTicket",
    e,
    n
  ),
  /**
   * Create a board ticket
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_createboardticket
   */
  create: (e, n) => m("boardticket", e, n)
}, Wa = {
  /**
   * Get a board with its columns
   * TODO: add a link to documentation
   */
  get: (e, n) => o(`board/${e}`, {}, n),
  /**
   * Update a board
   * TODO: add a link to documentation
   */
  update: (e, n, a) => l(`board/${e}`, n, a)
}, Qa = {
  /**
   * Create a board column
   *
   */
  create: (e, n) => m("boardcolumn", e, n),
  /**
   * Update the board column
   */
  update: (e, n, a) => l(`boardcolumn/${e}`, n, a)
}, et = {
  /**
   * Get all unique selling points
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getall_31
   */
  getAll: (e, n) => o(
    `uniquesellingpoint?vaultObjectId=${e}&projectObjectId=${n}`
  )
}, nt = {
  /**
   * Create a new visitor and start session
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_createvisitorandstartsession
   */
  startSession: (e, n) => m("visitor", e, n),
  /**
   * set end session timestamp
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updateendsessiontimestamp
   */
  updateSession: (e, n) => l(
    "promptosession/updateEndSessionTimestamp",
    e,
    n
  )
}, at = {
  /**
   * Create shared content collection
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_vmsharedcontentcollection_resource
   * @param {Object} params
   * @param {string} sessionToken
   */
  create: (e, n) => m("sharedcontentcollection", e, n),
  /**
   * Get shared content collection
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_get_3
   */
  get: (e, n, a) => o(
    `sharedcontentcollection/${e}`,
    a,
    n
  ),
  /**
   * Update shared content collection
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_3
   */
  update: (e, n, a) => l(
    `sharedcontentcollection/${e}`,
    a,
    n
  )
}, tt = {
  /**
   * Get unit type
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_44
   */
  get: (e, n) => o(`unittype/${e}`, {}, n)
}, rt = {
  /**
   * Get a custom field type
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getall_21
   */
  getAll: (e, n) => o("customfieldtypes", e, n),
  /**
   * Get all custom field types
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_37
   */
  get: (e, n) => o(`customfieldtypes/${e}`, {}, n)
}, it = {
  /**
   * Get Hubspot install oAuth url
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_gethubspotinstalloauthurl
   */
  getHubspotInstallOAuthUrl: (e, n) => o(
    `crmIntegration/activate/getHubspotInstallOAuthUrl/${e}`,
    {},
    n
  ),
  /**
   * Initiate Hubspot connection
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_activatehubspotconnection
   */
  initiateHubspotConnection: (e, n) => l(
    "crmIntegration/activate/hubspotConnection",
    e,
    n
  ),
  /**
   * Check Hubspot connection
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_checkhubspotconnection
   */
  checkHubspotConnection: (e, n) => o(
    `crmIntegration/check/hubspotConnection/${e}`,
    {},
    n
  ),
  /**
   * Update CRM integration
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_activatehubspotconnection
   */
  updateCrmIntegration: (e, n, a) => l(`crmIntegration/${e}`, n, a),
  /**
   * Get Hubspot contacts schema
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_gethubspotcontactsschema
   */
  getHubspotContactsSchema: (e, n) => o(
    `crmIntegration/hubspot/getContactsSchema/${e}`,
    {},
    n
  ),
  /**
   * Get a CRM integration
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_get_6
   */
  getCrmIntegration: (e, n) => o(`crmIntegration/${e}`, {}, n),
  /**
   * Activate Immoweb connection
   *
   */
  activateImmowebConnection: (e, n) => l(
    "integrations/immoweb/activateImmowebConnection",
    e,
    n
  ),
  /**
   * Check Immoweb connection
   */
  checkImmowebConnection: (e, n) => o(
    `integrations/immoweb/checkConnection/${e}`,
    {},
    n
  ),
  /**
   * Publish a unit to Immoweb
   *
   */
  publishUnitToImmoweb: (e, n) => l("integrations/immoweb/publishUnit", e, n),
  /**
   * Unublish a unit from Immoweb
   *
   */
  unpublishUnitFromImmoweb: (e, n) => l("integrations/immoweb/unpublishUnit", e, n),
  /**
   * Check unit published to Immoweb
   *
   */
  checkUnitPublishedToImmoweb: (e, n, a) => o(
    "integrations/immoweb/checkUnit",
    { vaultId: e, unitId: n },
    a
  ),
  /**
   * Get Immoweb estate configuration
   */
  getImmowebEstateConfiguration: (e, n) => o(
    "integrations/immoweb/estateConfiguration",
    { vaultId: e },
    n
  ),
  /**
   * Update Immoweb estate configuration
   */
  updateImmowebEstateConfiguration: (e, n) => l(
    "integrations/immoweb/estateConfiguration",
    e,
    n
  ),
  /**
   * Publish project to Immoweb
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_publishproject
   */
  publishProjectToImmoweb: (e, n) => l("integrations/immoweb/publishProject", e, n),
  /**
   * Unpublish project from Immoweb
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_unpublishproject
   */
  unpublishProjectFromImmoweb: (e, n) => l(
    "integrations/immoweb/unpublishProject",
    e,
    n
  ),
  /**
   * Check project published to Immoweb
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_checkproject
   */
  checkProjectPublishedToImmoweb: (e, n, a) => o(
    "integrations/immoweb/checkProject",
    { vaultId: e, projectId: n },
    a
  ),
  /**
   * Get the running task to get the james edition leads
   * https://vrtual-media-api-dev-v2.ew.r.appspot.com/asciidoc/index.html#_jameseditiongetperpetualtask
   * @param vaultId
   * @returns
   */
  jamesEditionGetPerpetualTask: (e, n) => o(
    `crmIntegration/jamesEdition/getPerpetualTask/${e}`,
    {},
    n
  ),
  checkMicrosoftDynamicsConnection: (e, n) => o(
    `crmIntegration/microsoftDynamics/checkConnection/${e}`,
    {},
    n
  )
}, ot = {
  getSignatureRequestEmbeddedSignUrl: (e, n, a, t, r) => e ? n ? a ? t ? o(
    `vaults/${e}/projectSections/${n}/signature/requests/${a}/signatures/${t}/signurl`,
    {},
    r
  ) : Promise.reject(new Error("no signatureId given")) : Promise.reject(new Error("no signatureRequestId given")) : Promise.reject(new Error("no projectObjectId given")) : Promise.reject(new Error("no vaultObjectId given")),
  /**
   * Get a 360 album by code
   */
  getAlbumByCode: (e) => {
    if (!e)
      return Promise.reject(new Error("no album code given"));
    const n = ue.base360Url;
    return new Promise((a, t) => {
      o(`${n}/albums/code=${e}/token`, {
        apiToken: ue.apiToken360
      }).then(({ data: r }) => {
        o(`${n}/albums/code=${e}/coverimage`, {
          token: r.token,
          apiToken: ue.apiToken360
        }).then((c) => {
          a({ data: c.data });
        });
      }).catch((r) => {
        t(r);
      });
    });
  }
};
var Pa = /* @__PURE__ */ ((e) => (e.active = "active", e.inactive = "inactive", e))(Pa || {});
const ct = {
  /**
   * Create a new contact
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_createcontact
   */
  create: (e, n) => m("contact", e, n),
  /**
   * Create contacts in bulk
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_createbulkcontact
   */
  createBulk: (e, n) => m(
    "contact/bulk/create",
    e,
    n
  ),
  /**
   * Update a contact
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updatecontact
   */
  update: (e, n, a) => l(
    `contact/${e}`,
    n,
    a
  ),
  /**
   * Update contacts in bulk
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_updatebulkcontact
   */
  updateBulk: (e, n) => l(
    "contact/bulk/update",
    e,
    n
  ),
  /**
   * Get a contact
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getcontact
   */
  get: (e, n) => o(`contact/${e}`, n),
  /**
   * Get all contacts in a vault
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_getallcontactsbyvault
   */
  getAll: (e, n, a) => o(
    `contact/vault/${e}`,
    n,
    a
  )
}, st = {
  /**
   * Get marketplace settings
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_get_28
   * @param {Object} params
   *
   */
  get: (e) => o("marketplaceProjectSettings", e),
  /**
   * Create marketplace settings
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_create_25
   * @param {Object} params
   * @param {string} sessionToken
   */
  create: (e, n) => m("marketplaceProjectSettings", e, n),
  /**
   * Update marketplace settings
   * https://devapiv2.vr-tual.media/asciidoc/index.html#_update_24
   * @param {string} sessionToken
   * @param {string} marketplaceProjectSettingsId
   * @param {Object} params
   */
  update: (e, n, a) => l(
    `marketplaceProjectSettings/${e}`,
    n,
    a
  )
};
export {
  sa as AllowedViews,
  ka as Analytics,
  Wa as Board,
  Qa as BoardColumn,
  _a as BoardTicket,
  Ea as ConfigurationSession,
  ct as Contact,
  Pa as ContactStatus,
  wa as ContentCollection,
  Ra as ContentItem,
  ca as ContentItemState,
  oa as ContentItemType,
  ua as CookieValidity,
  it as CrmIntegration,
  rt as CustomFieldType,
  Aa as GeneralFieldType,
  Fa as ImportUnitConfiguration,
  ga as LabelSize,
  Ma as Lead,
  Ia as LeadComment,
  Oa as Marketplace,
  st as MarketplaceProjectSettings,
  Ba as MarketplaceSettings,
  ot as Misc,
  $a as NavigationCollection,
  Na as NavigationItem,
  la as NavigationItemType,
  Da as NavigationTargetValue,
  Ta as Notification,
  La as PortalSettings,
  pa as Position,
  ja as Productboard,
  Ga as Project,
  ha as ProjectFileType,
  xa as ProjectFolderConfiguration,
  da as ProjectSectionType,
  ma as ProjectState,
  Va as PromptoSession,
  Ha as SalesPerson,
  L as ShareItem,
  at as SharedContentCollection,
  qa as ShowcaseConfiguration,
  ia as State,
  fa as TargetType,
  Ka as Tracking,
  Ya as TurnNavigationTarget,
  Ja as TurnPointOfView,
  za as Turntable,
  et as UniqueSellingPoint,
  Xa as Unit,
  va as UnitActionName,
  Sa as UnitFlow,
  ya as UnitState,
  tt as UnitType,
  Za as User,
  Ge as Vault,
  Ca as VaultUserRoles,
  nt as Visitor,
  ue as environment,
  E as performDeleteCall,
  o as performGetCall,
  m as performPostCall,
  l as performPutCall
};
